import React, { useContext } from 'react';
import {
    SttGrid,
    SttFileSelect,
    SttButton,
    SttTranslateHook,
    SttNotification
} from '@stt-componentes/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useSignalEffect } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { anexos } from '../../signals/envio-imagens';

const useStyles = makeStyles(theme => ({
    divAnexo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },

    botaoMenos: {
        minWidth: 0
    },

    divExternaAnexos: {
        maxHeight: '500px'
    },
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const Anexos = ({ exibirAviso = true, exibirAnexoExterno = true }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue, values } = useFormikContext();

    const uploadFile = (event, indice, removerAnexo) => {
        //Caso o anexo tenha sido excluída, remove a mesma do array de anexos no formulário e retira do array de anexos
        if (!event) {
            if (anexos.value.length > 1) {
                removerAnexo(indice);
            } else {
                setFieldValue(`anexo.${(indice)}`, {});
            }

            anexos.value = anexos.value.filter((_, i) => i !== indice);
        } else {
            if (event.target.files[0]) {
                //Trata nome e blob do anexo para exibição no carousel
                lerAnexoBase64(event.target.files[0]);
                //Seta o valor do anexo no campo correspondente do formulário
                setFieldValue(`anexo.${(indice)}`, event.target.files[0]);
            }
        }
    };

    const uploadFileExterno = (event) => {
        //Caso o anexo tenha sido excluída, remove a mesma do array de anexos no formulário e retira do array de anexos
        if (!event) {
            setFieldValue(`anexoExterno`, {});
        } else {
            setFieldValue(`anexoExterno`, event.target.files[0]);
        }
    };

    useSignalEffect(() => {
        if (anexos.length === 0 && values['anexo']) {
            //Trata nome e blob do anexo para exibição no carousel
            values['anexo'].forEach(file => {
                if (file && file.name) {
                    lerAnexoBase64(file);
                }
            });
        }
    });

    const lerAnexoBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            anexos.value = [
                ...anexos.value,
                {
                    nome: file.name,
                    anexo: reader.result,
                    ehImagem: file.type !== 'application/pdf'
                }
            ];
        };
    }

    const validarAnexo = (value) => {
        let error;
        if (!value || !(value instanceof File)) {
            error = strings.campoObrigatorio;
        } else if (value && !['image/png', 'image/bmp', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type)) {
            error = strings.anexoInvalido;
        }
        return error;
    }

    const validarAnexoExterno = (value) => {
        let error;
        if (value && !['.zip', '.rar'].includes(value.type)) {
            error = strings.anexoInvalido;
        }
        return error;
    }

    return (
        <>
            {
                exibirAviso &&
                <SttNotification severity="info" style={{ marginTop: '10px' }}>{strings.inclusaoImagemOpcional}</SttNotification>
            }
            <SttGrid container spacing={2} justifyContent="center" className={classes.container}>
                <SttGrid item xs={12} md={exibirAnexoExterno ? 6 : 12}>
                    <FieldArray
                        name={'anexo'}
                        render={({ remove, push }) => (
                            <>
                                <div className={classes.divExternaAnexos}>
                                    {values['anexo'] && values['anexo'].length > 0 &&
                                        values['anexo'].map((_, indice) => (
                                            <div key={indice}>
                                                <Field name={`anexo.${(indice)}`} validate={validarAnexo}>
                                                    {({
                                                        field: { name, value },
                                                        meta
                                                    }) => (
                                                        <>
                                                            <SttFileSelect
                                                                labelInput={strings.anexoEnvioImagens}
                                                                onFileChange={(event) => uploadFile(event, indice, remove)}
                                                                file={value}
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.anexoEnvioImagens,
                                                                    value: value && value.name,
                                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                                }}
                                                                accept={["image/jpg", "image/jpeg", "image/png", "image/bmp", "application/pdf"]}
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        ))}
                                </div>

                                {
                                    global.gConfig.multiplos_anexos &&
                                    <>
                                        <SttButton
                                            className={classes.botaoMais}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                push({});
                                            }}
                                        >
                                            +
                                        </SttButton>
                                        <SttButton
                                            className={classes.botaoMenos}
                                            variant="contained"
                                            disabled={values['anexo'] && values['anexo'].length == 1}
                                            onClick={() => {
                                                if (values['anexo'].length > 1) {
                                                    let indice = values['anexo'].length - 1;
                                                    remove(indice);
                                                    anexos.value = (anexs => anexs.filter((_, i) => i !== indice));
                                                }
                                            }}
                                            color="primary">
                                            -
                                        </SttButton>
                                    </>
                                }

                            </>
                        )}
                    />
                </SttGrid>
                {
                    exibirAnexoExterno &&
                    <SttGrid item xs={12} md={6}>
                        <Field name={`anexoExterno`} validate={validarAnexoExterno}>
                            {({
                                field: { name, value },
                                meta
                            }) => (
                                <>
                                    <SttFileSelect
                                        labelInput={strings.outroAnexoExame}
                                        onFileChange={(event) => uploadFileExterno(event)}
                                        file={value}
                                        inputprops={{
                                            name: name,
                                            label: strings.outroAnexoExame,
                                            value: value && value.name,
                                            error: meta.touched && meta.error ? meta.error : undefined
                                        }}
                                        accept={[".zip", ".rar"]}
                                    />
                                </>
                            )}
                        </Field>
                    </SttGrid>
                }
                <SttGrid item xs={12}>
                    <Carousel statusFormatter={(current, total) => `Imagem ${current} de ${total}`}>
                        {
                            anexos.value && anexos.value.filter && anexos.value.filter(a => a.ehImagem).map((anexo, indice) => {
                                return (
                                    <div className={classes.divAnexo} key={indice}>
                                        <img src={anexo.anexo} className={classes.imgAtual} />
                                        <p className="legend">{anexo.nome}</p>
                                    </div>
                                );
                            })
                        }
                    </Carousel>
                </SttGrid>
            </SttGrid>
        </>
    );
}

export default Anexos;