import {
    DESCRICAO_ESTUDO,
    DECS,
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    ID_PACIENTE,
    ID_MEDICO_SOLICITANTE,
    NOME_MEDICO_SOLICITANTE,
    OBSERVACAO,
    INDICE_EVENTOS_RESPIRATORIOS,
    TAXA_IER,
    SPO2,
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export const values = (idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante) => {
    let val = {
        [ID_EXAME]: idExame,
        [ID_LAUDO_EMISSAO]: idLaudoEmissao,
        [ID_PACIENTE]: idPaciente,
        [ID_MEDICO_SOLICITANTE]: idSolicitante,
        [NOME_MEDICO_SOLICITANTE]: nomeSolicitante,
        [DESCRICAO_ESTUDO]: 'Exame de Polissonografia - Tipo 3',
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [CID_10]: [],
        [DECS]: [],
        [OBSERVACAO]: '',
        [INDICE_EVENTOS_RESPIRATORIOS]: null,
        [TAXA_IER]: '',
        [SPO2]: ''
    };
    
    return val;
}