import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    MateriaisSuporte,
    SttTranslateHook,
    SttNotificationScreen,
    SttHelpdeskShortcut
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../security/acl';
import { PERMISSOES } from '../common/Constants';
import { getHeaders } from '../request';
import usuario from '../signals/usuario';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const Menu = () => {
    useSignals();

    const submenus = useSignal([]);
    const tabAtual = useSignal(0);
    const openMS = useSignal(false);
    const openSS = useSignal(false);
    const exibirNotificacao = useSignal(false);

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const navigate = useNavigate();

    useSignalEffect(() => {
        let menuOptions = [];

        if (usuario) {
            if (!temPermissaoRBAC(usuario, PERMISSOES.POLISSONOGRAFIA)) {
                navigate(`/nao-autorizado`);
                return;
            }

            exibirNotificacao.value = true;
            menuOptions = [...menuOptions, {
                id: '/',
                text: strings.inicio,
                button: true,
                onClick: () => {
                    navigate("/");
                }
            }];

            if (temPermissaoRBAC(usuario, PERMISSOES.SOLICITAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/solicitacao',
                    text: strings.solicitacao,
                    button: true,
                    onClick: () => {
                        navigate("/solicitacao");
                    }
                }];
            }


            if (temPermissaoRBAC(usuario, [PERMISSOES.VISUALIZAR_SOLICITACAO, PERMISSOES.CRIAR_EXAME])) {
                menuOptions = [...menuOptions, {
                    id: '/imagens',
                    text: strings.envioImagens,
                    button: true,
                    onClick: () => {
                        navigate("/imagens");
                    }
                }];
            }

            if (temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/exames',
                    text: strings.exames,
                    button: true,
                    onClick: () => {
                        navigate("/exames");
                    }
                }];
            }

            if (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME_FABRICA)) {
                menuOptions = [...menuOptions, {
                    id: '/laudo',
                    text: strings.laudo,
                    button: true,
                    onClick: () => {
                        navigate("/laudo");
                    }
                }];
            }

            // if (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRATIVO)) {
            //     menuOptions = [...menuOptions, {
            //         id: '/administrativo',
            //         text: strings.administrativo,
            //         button: true,
            //         onClick: () => {
            //             navigate("/administrativo");
            //         }
            //     }];
            // }

            submenus.value = menuOptions;
        }
    });

    useEffect(() => {
        submenus.value.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                tabAtual.value = i;
            }
        });
    }, [submenus, location]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={strings.modulo}
                config={global.gConfig}
                submenus={submenus.value}
                selectedTab={tabAtual.value}
                callbackNavigationBar={(a, b) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={usuario.value.perfisRBAC}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
        </div>
    );
};

export default Menu;
