import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const gerandoPdf = signal(false);

const Aba = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { ...other } = props;
    const classes = useStyles();
    useSignals();

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const POLISSONO_API_BASE_URL = global.gConfig.url_base_polissonografia;

    const imprimir = (idExame) => {
        gerandoPdf.value = true
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: {
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                imagens: true
            }
        };

        axios
            .get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
            }).finally(() => {
                gerandoPdf.value = false;
            });
    }

    const imprimirProtocolo = (exame) => {
        gerandoPdf.value = true;
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios.get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                gerandoPdf.value = false;
                window.open(response.data);
            })
            .catch(err => {
                gerandoPdf.value = false;
                console.log(err);
            });
    }

    const imprimirTermo = (exame) => {
        let tab = window.open();
        if (tab) {
            gerandoPdf.value = true;
            tab.document.write('<p>Aguarde...</p>');
            const idBuffer = Buffer.from(exame.id_paciente.toString());
            let url = `${POLISSONO_API_BASE_URL}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${exame.uf}`;
            axios.get(url, { headers: getHeaders(), responseType: 'blob' })
                .then((response) => {
                    gerandoPdf.value = false;
                    if (response.data) {
                        const fileURL = URL.createObjectURL(response.data);
                        tab.location = fileURL;
                    }
                })
                .catch(err => {
                    gerandoPdf.value = false;
                    console.log(err);
                });
        }
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf.value}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

export default Aba;