export const INDICACAO_CLINICA = 'indicacaoClinica';
export const DEMORA_INICIAR_SONO = 'demoraIniciarSono';
export const TEMPO_DEMORA_INICIAR_SONO = 'tempoDemoraIniciarSono';
export const ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DEMORA_INICIAR_SONO = 'idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono';
export const POSSUI_DESPERTARES_MEIO_SONO = 'possuiDespertaresMeioSono';
export const TEMPO_DESPERTARES_MEIO_SONO = 'tempoDespertaresMeioSono';
export const ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTARES_MEIO_SON = 'idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon';
export const DESPERTA_CEDO_DEMAIS = 'despertaCedoDemais';
export const TEMPO_DESPERTA_CEDO_DEMAIS = 'tempoDespertaCedoDemais';
export const ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTA_CEDO_DEMAIS = 'idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais';
export const INTENSIDADE_SONOLENCIA_FADIGA = 'intensidadeSonolenciaFadiga';
export const POSSUI_RONCO = 'possuiRonco';
export const POSSUI_DIFICULDADE_RESPIRAR = 'possuiDificuldadeRespirar';
export const POSSUI_MOVIMENTOS_REPETIDOS_INVOLUNTARIOS = 'possuiMovimentosRepetidosInvoluntarios';
export const POSSUI_DESCONFORTO_PERNAS = 'possuiDesconfortoPernas';
export const PROBLEMAS_SAUDE = 'problemasSaude';
export const MEDICAMENTOS = 'medicamentos';
export const MEDICAMENTO = 'medicamento';
export const MOTIVO = 'motivo';