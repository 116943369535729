import { signal } from "@preact/signals-react";
import { estadoInicialForm } from '../../componentes/exame/estadoInicialForm';
const filtrosIniciais = estadoInicialForm();

export const examesSemLaudo = signal(null);
export const filtros = signal(filtrosIniciais);
export const listar = signal(false);

export const resetFiltros = () => {
    filtros.value = filtrosIniciais;
}
