import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { getHeaders } from '../../request';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';
import BlockIcon from '@material-ui/icons/Block';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WebIcon from '@material-ui/icons/Web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Form from './formPesquisa';
import Moment from 'react-moment';
import moment from 'moment';
import { TIPO_ABA_EXAME, SITUACAO_LAUDO, SITUACAO } from './constantes';
import { MODALIDADE, PERFIL, PERMISSOES } from '../../common/Constants';
import { temPerfilRBAC, temPermissaoRBAC, temPermissaoRede } from '../../security/acl';
import Laudo from '../laudo';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTablePagination,
    SttCircularProgress,
    SttMenu,
    SttMenuItem,
    SttListItemIcon,
    SttListItemText,
    SttDivider,
    SttGrid,
    SttTranslateHook
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import usuario from '../../signals/usuario';
import { batch, computed, useSignalEffect } from '@preact/signals-react';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { filtros, listar } from '../../signals/exame';
import laudar from '../../signals/laudo';
import TrocarRede from './trocarRede';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { ListaExames } from '@stt-utilitarios/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    divider: {
        marginTop: theme.spacing(1)
    }
}));

const Pesquisa = (props) => {
    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        tipo,
        id,
        laudador,
        imprimirFn,
        imprimirProtocoloFn,
        imprimirTermoFn
    } = props;

    const page = useSignal(filtros.value.page || global.gConfig.pagination.start)
    const count = useSignal(filtros.value.count || global.gConfig.pagination.count);
    const buscaEmAndamento = useSignal(false);
    const redes = useSignal([]);
    const startBusca = computed(() => page.value * count.value);
    const exames = useSignal([]);
    const totalRegistros = useSignal(0);
    const colspan = useSignal(6);
    const menuOptions = useSignal([]);
    const carregarRedes = useSignal(false);
    const permissaoLaudoInvPrio = useSignal([]);
    const priorizar = useSignal(false);
    const invalidar = useSignal(false);
    const idExameSelecionado = useSignal(null);
    const idInstituicaoExameSelecionado = useSignal(null);

    // Signals para troca de rede
    const examesSelecionadosTrocaRede = useSignal([]);
    const redeExameSelecionaroTrocaRede = useSignal(null);
    const trocarRede = useSignal(false);

    const preTrocarRede = (exame) => {
        if (!Array.isArray(exame)) {
            examesSelecionadosTrocaRede.value = [exame.id];
        }
        redeExameSelecionaroTrocaRede.value = exame.id_rede_telemedicina;
        trocarRede.value = true;
    }

    const preInvalidar = (exame) => {
        batch(() => {
            idExameSelecionado.value = exame.id;
            idInstituicaoExameSelecionado.value = exame.id_instituicao;
            invalidar.value = true;
        });
    }

    const prePriorizar = (exame) => {
        batch(() => {
            idExameSelecionado.value = exame.id;
            idInstituicaoExameSelecionado.value = exame.id_instituicao;
            priorizar.value = true;
        });
    }

    useSignalEffect(() => {
        if (carregarRedes.value) {
            axios.get(`${global.gConfig.url_base_utilitarios}/rede?modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        redes.value = itens;
                    } else {
                        redes.value = [];
                    }
                })
                .catch(err => console.log(err));
        }
    });

    useSignalEffect(() => {
        if (usuario.value.perfisRBAC) {
            let rede;
            usuario.value.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === id) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                permissaoLaudoInvPrio.value = [
                    temPermissaoRede(rede, PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede, PERMISSOES.PRIORIZAR_EXAME),
                    temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME)
                ];
            }
        }
    })

    useSignalEffect(() => {
        if (temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) {
            colspan.value = 9;
            carregarRedes.value = true;
        } else if (temPerfilRBAC(usuario, [PERFIL.TECNICO, PERFIL.MEDICO_LAUDADOR, PERFIL.MEDICO_EXECUTOR])) {
            colspan.value = 7;
        }

        let menu = [];
        if (temPermissaoRBAC(usuario, [PERMISSOES.VISUALIZAR_EXAME, PERMISSOES.VISUALIZAR_LAUDO])) {
            menu.push({
                apenasExameValido: false,
                aplicaA: [
                    SITUACAO_LAUDO.COM_LAUDO,
                    SITUACAO_LAUDO.SEM_LAUDO,
                    SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                    SITUACAO_LAUDO.LAUDO_EMISSAO,
                    SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
                    SITUACAO_LAUDO.DIGITADO,
                    SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
                    SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
                ],
                icon: VisibilityIcon,
                title: strings.ver,
                createOnClick: (exame) => {
                    visualizarExame(exame.id);
                },
            });
        }
        if (permissaoLaudoInvPrio.value[2]) {
            menu.push({
                apenasExameValido: true,
                aplicaA: [
                    SITUACAO_LAUDO.COM_LAUDO
                ],
                icon: DescriptionIcon,
                title: strings.laudo,
                createOnClick: (exame) => {
                    idExameSelecionado.value = exame.id;
                    laudar.value = true;
                },
            });
        }
        if ((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio.value[0]) || (tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) {
            menu.push({
                apenasExameValido: true,
                aplicaA: [
                    SITUACAO_LAUDO.SEM_LAUDO
                ],
                icon: BlockIcon,
                title: strings.invalidar,
                createOnClick: (exame) => preInvalidar(exame),
            });
        }
        if (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO)) {
            menu.push({
                apenasExameValido: true,
                aplicaA: [
                    SITUACAO_LAUDO.COM_LAUDO
                ],
                icon: PriorityHighIcon,
                title: strings.invalidar,
                createOnClick: (exame) => preInvalidar(exame),
            });
        }
        if ((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio.value[1]) || (tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) {
            menu.push({
                apenasExameValido: true,
                aplicaA: [
                    SITUACAO_LAUDO.SEM_LAUDO
                ],
                icon: PriorityHighIcon,
                title: strings.priorizar,
                createOnClick: (exame) => prePriorizar(exame),
            });
        }
        menu.push({
            apenasExameValido: true,
            aplicaA: [
                SITUACAO_LAUDO.COM_LAUDO,
                SITUACAO_LAUDO.SEM_LAUDO,
                SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                SITUACAO_LAUDO.LAUDO_EMISSAO,
                SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
                SITUACAO_LAUDO.DIGITADO,
                SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
                SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
            ],
            icon: PrintIcon,
            title: strings.imprimir,
            createOnClick: (exame) => imprimirFn(exame.id),
        });
        menu.push({
            apenasExameValido: true,
            aplicaA: [
                SITUACAO_LAUDO.COM_LAUDO,
                SITUACAO_LAUDO.SEM_LAUDO,
                SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                SITUACAO_LAUDO.LAUDO_EMISSAO,
                SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
                SITUACAO_LAUDO.DIGITADO,
                SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
                SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
            ],
            icon: WebIcon,
            title: strings.protocolo,
            createOnClick: (exame) => imprimirProtocoloFn(exame),
        });
        if (temPermissaoRBAC(usuario, PERMISSOES.TROCAR_REDE)) {
            menu.push({
                apenasExameValido: true,
                aplicaA: [
                    SITUACAO_LAUDO.SEM_LAUDO
                ],
                icon: SwapHorizIcon,
                title: strings.trocarRede,
                createOnClick: (exame) => preTrocarRede(exame),
            });
        }
        menu.push({
            apenasExameValido: true,
            aplicaA: [
                SITUACAO_LAUDO.COM_LAUDO,
                SITUACAO_LAUDO.SEM_LAUDO,
                SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                SITUACAO_LAUDO.LAUDO_EMISSAO,
                SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
                SITUACAO_LAUDO.DIGITADO,
                SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
                SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
            ],
            icon: AssignmentIcon,
            title: strings.imprimirTermo,
            createOnClick: (exame) => imprimirTermoFn(exame),
        });
        menuOptions.value = menu;
    });

    useSignalEffect(() => {
        if (!laudar.value && !priorizar.value && !invalidar.value) {
            batch(() => {
                idExameSelecionado.value = null;
                idInstituicaoExameSelecionado.value = null;
            });
        }
    });

    useEffect(() => {
        if (location.state?.from === 'visualizar') {
            listar.value = true;
        }
    }, [location.state]);

    const visualizarExame = (id) => {
        navigate('/exames');
        navigate('/exames/visualizar', { state: { exame: id } });
        laudar.value = false;
    }

    const resetPageCount = () => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            listar.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            listar.value = true;
        });
    };

    const callbackBusca = (dados) => {
        batch(() => {
            if (dados) {
                totalRegistros.value = parseInt(dados.totalRegistros);
                exames.value = dados.itens;
            } else {
                totalRegistros.value = 0;
                exames.value = [];
            }
            listar.value = false;
        });
    }



    return (
        <>
            {
                laudar.value ?
                    <Laudo idExame={idExameSelecionado} laudar={laudar} /> :
                    <>
                        <Form
                            tipoAba={tipo}
                            idAba={id}
                            laudador={laudador}
                            callbackBusca={callbackBusca}
                            resetPageCount={resetPageCount}
                            page={page}
                            count={count}
                            buscaEmAndamento={buscaEmAndamento}
                            startBusca={startBusca}
                            redes={redes}
                        />

                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttDivider className={classes.divider} />
                                <div className={classes.tableWrapper}>
                                    <SttTable>
                                        {
                                            examesSelecionadosTrocaRede.length > 0 && temPermissaoRBAC(usuario, PERMISSOES.TROCAR_REDE) &&
                                            <caption>
                                                <div className={classes.contentWrapper}>
                                                    {
                                                        examesSelecionadosTrocaRede.value.length === 1 && `${examesSelecionadosTrocaRede.value.length} ${strings.exameSelecionado}`
                                                    }
                                                    {
                                                        examesSelecionadosTrocaRede.value.length > 1 && `${examesSelecionadosTrocaRede.value.length} ${strings.examesSelecionados}`
                                                    }
                                                    <SttButton
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => preTrocarRede(examesSelecionadosTrocaRede.value)}
                                                    >
                                                        {strings.trocarRede}
                                                    </SttButton>
                                                </div>
                                            </caption>
                                        }
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell>
                                                    {strings.dataExame}
                                                </SttTableCell>
                                                {
                                                    (temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) &&
                                                    <>
                                                        <SttTableCell>
                                                            {`${strings.municipio}/${strings.uf}`}
                                                        </SttTableCell>
                                                        <SttTableCell>
                                                            {strings.instituicao}
                                                        </SttTableCell>
                                                    </>
                                                }
                                                {
                                                    (temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL, PERFIL.TECNICO, PERFIL.MEDICO_LAUDADOR])) &&
                                                    <SttTableCell>
                                                        {strings.tecnico}
                                                    </SttTableCell>
                                                }
                                                <SttTableCell>
                                                    {strings.requisicao}
                                                </SttTableCell>
                                                <SttTableCell>
                                                    {strings.paciente}
                                                </SttTableCell>
                                                <SttTableCell>
                                                    {strings.descricao}
                                                </SttTableCell>
                                                <SttTableCell>
                                                    {strings.situacao}
                                                </SttTableCell>
                                                <SttTableCell align="center">
                                                    {strings.opcoes}
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            {
                                                buscaEmAndamento.value
                                                    ?
                                                    <SttTableRow key={-1}>
                                                        <SttTableCell colSpan={colspan.value} align="center">
                                                            <SttCircularProgress />
                                                        </SttTableCell>
                                                    </SttTableRow>
                                                    :
                                                    (
                                                        exames.value.length === 0 ?
                                                            <SttTableRow key={-1}>
                                                                <SttTableCell colSpan={colspan.value} align="center">
                                                                    {strings.nenhumRegistroEncontrado}
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                            :
                                                            exames.value.map((row, index) => (
                                                                <SttTableRow key={index}>
                                                                    <SttTableCell><Moment format='DD/MM/YYYY HH:mm'>{row.data_exame}</Moment></SttTableCell>
                                                                    {
                                                                        (temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) &&
                                                                        <>
                                                                            <SttTableCell>{`${row.municipio}/${row.uf}`}</SttTableCell>
                                                                            <SttTableCell>{row.instituicao}</SttTableCell>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (temPerfilRBAC(usuario, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL, PERFIL.TECNICO, PERFIL.MEDICO_LAUDADOR])) &&
                                                                        <SttTableCell>{row.funcionario_envio}</SttTableCell>

                                                                    }
                                                                    <SttTableCell>{row.requisicao}</SttTableCell>
                                                                    <SttTableCell>{row.paciente}</SttTableCell>
                                                                    <SttTableCell>{row.descricao_exame}</SttTableCell>
                                                                    <SttTableCell>
                                                                        <Chip
                                                                            style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
                                                                            label={ListaExames.getTextoLegenda(row, strings)}
                                                                            size="small"
                                                                        />
                                                                    </SttTableCell>
                                                                    <SttTableCell align="center">
                                                                        {
                                                                            menuOptions.value.length > 0 &&
                                                                            <PopupState variant="popover">
                                                                                {(popupState) => (
                                                                                    <>
                                                                                        <IconButton aria-haspopup="true" {...bindTrigger(popupState)}>
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <SttMenu {...bindMenu(popupState)}>
                                                                                            {menuOptions.value.map((item, idx) => {
                                                                                                if (item.aplicaA.includes(row.situacao_laudo) && ((row.situacao === SITUACAO.VALIDO && item.apenasExameValido) || !item.apenasExameValido)) {
                                                                                                    return (
                                                                                                        <SttMenuItem
                                                                                                            key={idx}
                                                                                                            onClick={() => {
                                                                                                                popupState.close();
                                                                                                                item.createOnClick(row);
                                                                                                            }}
                                                                                                        >
                                                                                                            <SttListItemIcon>
                                                                                                                <item.icon />
                                                                                                            </SttListItemIcon>
                                                                                                            <SttListItemText primary={item.title} />
                                                                                                        </SttMenuItem>
                                                                                                    );
                                                                                                }
                                                                                                return null;
                                                                                            })}
                                                                                        </SttMenu>
                                                                                    </>
                                                                                )}
                                                                            </PopupState>
                                                                        }
                                                                    </SttTableCell>
                                                                </SttTableRow>
                                                            ))
                                                    )
                                            }
                                        </SttTableBody>
                                    </SttTable>
                                </div>
                            </SttGrid>
                        </SttGrid>
                        {
                            exames.value.length > 0 &&
                            <>
                                <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                    component="div"
                                    count={totalRegistros.value}
                                    rowsPerPage={count.value}
                                    page={page.value}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={strings.linhasPorPagina}
                                    labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                                />
                                {
                                    priorizar.value && ((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[1]) || (tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) &&
                                    <Priorizar
                                        priorizar={priorizar}
                                        idExame={idExameSelecionado.value}
                                        idInstituicaoExameSelecionado={idInstituicaoExameSelecionado.value}
                                    />
                                }
                                {
                                    invalidar.value && ((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[0]) || (tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) &&
                                    <Invalidar
                                        idExame={idExameSelecionado.value}
                                        idInstituicao={idInstituicaoExameSelecionado.value}
                                        invalidar={invalidar}
                                    />
                                }

                                {
                                    trocarRede.value && temPermissaoRBAC(usuario, PERMISSOES.TROCAR_REDE) &&
                                    <TrocarRede
                                        exame={examesSelecionadosTrocaRede.value}
                                        redes={redes.value}
                                        trocarRede={trocarRede}
                                        redeExameSelecionado={redeExameSelecionaroTrocaRede.value}
                                    />
                                }
                            </>
                        }
                    </>
            }
        </>
    );
};

Pesquisa.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    tipo: PropTypes.string.isRequired
};

export default Pesquisa;