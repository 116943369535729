let defaultExport = null;

if (false) {
  defaultExport = {
    "paciente": {
      "id": 2014,
      "cpf": " 075.850.579-54",
      "cns": "728790182690006",
      "nome": "EDUARDO BECKHAUSER",
      "nomeMae": "nome da mae",
      "genero": {
        "id": 1,
        "descricao": "Masculino"
      },
      "dataNascimento": "1993-09-20T12:00:00.000Z",
      "municipio": {
        "id": 1,
        "nome": "FLORIANÓPOLIS"
      },
      "uf": {
        "id": 1,
        "nome": "Santa Catarina",
        "sigla": "SC"
      },
      "pais": {
        "id": 1,
        "nome": "BRASIL",
        "sigla": "BRA"
      },
      "peso": "100",
      "altura": "190",
      "profissao": "",
      "grupoSanguineo": null,
      "fatorRh": null,
      "raca": null,
      "cep": "88064000",
      "logradouro": "Rodovia Baldicero Filomeno",
      "bairro": "Ribeirão da Ilha",
      "tipoContato1": 1,
      "contato1": "4830201020",
      "tipoContato2": 2,
      "contato2": "",
      "idade": ""
    },
    "solicitante": {
      "medico": {
        "id": 140614,
        "nome": "SOLICITANTE POLISSONOGRAFIA",
        "cpf": ""
      },
      "outroMedico": null,
      "instituicao": {
        "nome": "HOSPITAL UNIVERSITARIO",
        "id": 5244,
        "uf": "SC"
      }
    },
    "indicacaoClinica": {
      "demoraIniciarSono": "N",
      "possuiDespertaresMeioSono": "N",
      "despertaCedoDemais": "N",
      "tempoDemoraIniciarSono": null,
      "idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono": null,
      "tempoDespertaresMeioSono": null,
      "idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon": null,
      "tempoDespertaCedoDemais": null,
      "idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais": null,
      "intensidadeSonolenciaFadiga": "N",
      "possuiRonco": "N",
      "possuiDificuldadeRespirar": "N",
      "possuiMovimentosRepetidosInvoluntarios": "N",
      "possuiDesconfortoPernas": "N",
      "problemasSaude": ""
    },
    "medicamentos": [
      {
        "medicamento": "1",
        "motivo": "1"
      }
    ]
  };
} else {
  defaultExport = {
    paciente: {
      id: '',
      cpf: '',
      cns: '',
      nome: '',
      genero: null,
      dataNascimento: null,
      municipio: null,
      uf: null,
      pais: null,
      peso: '',
      altura: '',
      profissao: '',
      cep: '',
      logradouro: '',
      bairro: '',
      tipoContato1: null,
      contato1: ''
    },
    solicitante: {
      medico: null,
      outroMedico: null,
      instituicao: null
    },
    indicacaoClinica: {
      demoraIniciarSono: null,
      possuiDespertaresMeioSono: null,
      despertaCedoDemais: null,
      tempoDemoraIniciarSono: null,
      idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono: null,
      tempoDespertaresMeioSono: null,
      idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon: null,
      tempoDespertaCedoDemais: null,
      idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais: null,
      intensidadeSonolenciaFadiga: null,
      possuiRonco: null,
      possuiDificuldadeRespirar: null,
      possuiMovimentosRepetidosInvoluntarios: null,
      possuiDesconfortoPernas: null,
      problemasSaude: ''
    },
    medicamentos: []
  };
}

export default defaultExport;