import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttGrid,
    SttTranslateHook,
    SttNotification,
    SttDivider,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttText,
    SttTableBody
} from '@stt-componentes/core';
import ModalNovosAnexos from './modalAnexo';
import Form from './form';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import {
    CONSTANTE_DESCRICAO_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL,
    CONSTANTE_DESCRICAO_ENVIO_EXAMES_GRAU_DESCONFORTO,
    CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS,
    CONSTANTE_DESCRICAO_SOLICITACAO_POSSUI_RONCO,
    CONSTANTE_DESCRICAO_SOLICITACAO_SENTE_SONOLENCIA
} from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    },
    textItemWrapper: {
        padding: 0
    },
    notification: {
        margin: theme.spacing(1),
        cursor: 'pointer'
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const Laudo = ({ dados, laudar, arquivosExame, callbackProximoExame, imagensAtualizadas }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const exame = dados.value;

    const exibirModalNovosAnexos = useSignal(false);

    return (
        <SttGrid container spacing={1}>
            <SttGrid item xs={12}>
                <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                    <span>{`${exame.exame.descricao_exame} - ${exame.exame.data_exame}`}</span>
                    <span>{`${exame.exame.nome_instituicao} - ${exame.exame.nome_cidade}/${exame.exame.sigla_uf}`}</span>
                </SttHeading>

                <SttExpansionPanel
                    title={strings.paciente}
                    compact
                    children={
                        <>
                            <SttTextItem title={strings.nome} content={exame.exame.nome_paciente} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem title={strings.dataNascimento} content={exame.exame.data_nascimento_paciente_formatada} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem title={strings.sexo} content={exame.exame.sexo_paciente} wrapperClass={classes.textItemWrapper} />
                            {exame.indicacao?.peso_paciente && <SttTextItem title={strings.peso} content={`${exame.indicacao.peso_paciente} kg`} wrapperClass={classes.textItemWrapper} />}
                            {exame.indicacao?.altura_paciente && <SttTextItem title={strings.altura} content={`${exame.indicacao.altura_paciente} cm`} wrapperClass={classes.textItemWrapper} />}
                            <SttTextItem title={strings.idade} content={`${exame.exame.idade_paciente} anos`} wrapperClass={classes.textItemWrapper} />
                            {exame.exame.cartao_sus_paciente && <SttTextItem title={strings.cns} content={exame.exame.cartao_sus_paciente} wrapperClass={classes.textItemWrapper} />}
                        </>
                    }
                />

                {
                    exame.indicacao &&
                    <>
                        <SttDivider className={classes.divider} />
                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            compact
                            children={
                                <div>
                                    {exame.indicacao.demora_iniciar_sono !== null && <SttTextItem title={strings.demoraIniciarSono} content={exame.indicacao.demora_iniciar_sono ? `${strings.sim}, nos(as) últimos(as) ${exame.indicacao.tempo_demora_iniciar_sono} ${exame.indicacao.especificacao_tempo_demora_iniciar_sono}` : strings.nao} />}
                                    {exame.indicacao.possui_despertares_meio_sono !== null && <SttTextItem title={strings.variosDespertaresMeioSono} content={exame.indicacao.possui_despertares_meio_sono ? `${strings.sim}, nos(as) últimos(as) ${exame.indicacao.tempo_despertares_meio_sono} ${exame.indicacao.especificacao_tempo_despertares_meio_sono}` : strings.nao} />}
                                    {exame.indicacao.desperta_cedo_demais !== null && <SttTextItem title={strings.despertaCedoDemais} content={exame.indicacao.desperta_cedo_demais ? `${strings.sim}, nos(as) últimos(as) ${exame.indicacao.tempo_desperta_cedo_demais} ${exame.indicacao.especificacao_tempo_desperta_cedo_demais}` : strings.nao} />}
                                    {exame.indicacao.intensidade_sonolencia_fadiga && <SttTextItem title={strings.tituloSenteSonolenciaDuranteDia} content={CONSTANTE_DESCRICAO_SOLICITACAO_SENTE_SONOLENCIA(strings, exame.indicacao.intensidade_sonolencia_fadiga)} />}
                                    {exame.indicacao.possui_ronco && <SttTextItem title={strings.tituloRonca} content={CONSTANTE_DESCRICAO_SOLICITACAO_POSSUI_RONCO(strings, exame.indicacao.possui_ronco)} />}
                                    {exame.indicacao.possui_dificuldade_respirar != null && <SttTextItem title={strings.tituloRelatouDificuldadeRespirar} content={exame.indicacao.possui_dificuldade_respirar ? strings.sim : strings.nao} />}
                                    {exame.indicacao.possui_movimentos_repetidos_involuntarios && <SttTextItem title={strings.movimentosRepetidosDuranteSono} content={CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS(strings, exame.indicacao.possui_movimentos_repetidos_involuntarios)} />}
                                    {exame.indicacao.possui_desconforto_pernas && <SttTextItem title={strings.desconfortoPernas} content={CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS(strings, exame.indicacao.possui_desconforto_pernas)} />}
                                    {exame.indicacao.problemas_saude && <SttTextItem title={strings.problemasSaude} content={exame.indicacao.problemas_saude} />}

                                    {
                                        exame.indicacao.medicamentos?.length > 0 &&
                                        <>
                                            <div className={classes.tableWrapper}>
                                                <SttTable>
                                                    <SttTableHead>
                                                        <SttTableRow>
                                                            <SttHidden smDown>
                                                                <SttTableCell width="30%">
                                                                    {strings.medicamento}
                                                                </SttTableCell>
                                                                <SttTableCell width="70%">
                                                                    {strings.motivo}
                                                                </SttTableCell>
                                                            </SttHidden>
                                                            <SttHidden mdUp>
                                                                <SttTableCell width="100%" colSpan="2">{strings.medicamentos}</SttTableCell>
                                                            </SttHidden>
                                                        </SttTableRow>
                                                    </SttTableHead>
                                                    <SttTableBody>
                                                        {
                                                            exame.indicacao.medicamentos.map((row, index) => (
                                                                <SttTableRow key={index}>
                                                                    <SttHidden smDown>
                                                                        <SttTableCell>{row.medicamento}</SttTableCell>
                                                                        <SttTableCell>{row.motivo}</SttTableCell>
                                                                    </SttHidden>
                                                                    <SttHidden mdUp>
                                                                        <SttTableCell width="96%">
                                                                            <SttText size="small">
                                                                                <b>{strings.medicamento}:</b> {row.medicamento}
                                                                            </SttText>
                                                                            <SttText size="small">
                                                                                <b>{strings.motivo}:</b> {row.motivo}
                                                                            </SttText>
                                                                        </SttTableCell>
                                                                    </SttHidden>
                                                                </SttTableRow>
                                                            ))
                                                        }
                                                    </SttTableBody>
                                                </SttTable>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        />
                    </>
                }

                {
                    exame.exame &&
                    <SttExpansionPanel
                        title={strings.dadosExame}
                        compact
                        children={
                            <div>
                                {exame.exame.observacao && <SttTextItem title={strings.observacao} content={exame.exame.observacao} />}
                                {exame.exame.grau_desconforto_aparelho_polissonografia && <SttTextItem title={strings.grauDesconfortoAparelhoPolissonografia} content={CONSTANTE_DESCRICAO_ENVIO_EXAMES_GRAU_DESCONFORTO(strings, exame.exame.grau_desconforto_aparelho_polissonografia)} />}
                                {exame.exame.hora_adormecer && <SttTextItem title={strings.horasDeitouDormir} content={`${exame.exame.hora_adormecer.hours} hora(s) e ${exame.exame.hora_adormecer.minutes ? exame.exame.hora_adormecer.minutes : '00'} minuto(s).`} />}
                                {exame.exame.tempo_demora_iniciar_sono && <SttTextItem title={strings.tempoDemorouIniciarSono} content={`${exame.exame.tempo_demora_iniciar_sono.hours} hora(s) e ${exame.exame.tempo_demora_iniciar_sono.minutes ? exame.exame.tempo_demora_iniciar_sono.minutes : '00'} minuto(s).`} />}
                                {exame.exame.quantidade_despertou_noite && <SttTextItem title={strings.vezesDespertouDuranteNoite} content={exame.exame.quantidade_despertou_noite} />}
                                {exame.exame.tempo_dormiu_noite && <SttTextItem title={strings.tempoAchaDormiuEstaNoite} content={`${exame.exame.tempo_dormiu_noite.hours} hora(s) e ${exame.exame.tempo_dormiu_noite.minutes ? exame.exame.tempo_dormiu_noite.minutes : '00'} minuto(s).`} />}
                                {exame.exame.grau_comparacao_sono_habitual && <SttTextItem title={strings.comparadoSonoHabitualSonoNoite} content={CONSTANTE_DESCRICAO_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL(strings, exame.exame.grau_comparacao_sono_habitual)} />}
                                {exame.exame.especificacao_causa_desconforto && <SttTextItem title={strings.descricaoCausasDesconforto} content={exame.exame.especificacao_causa_desconforto} />}
                            </div>
                        }
                    />
                }

                <SttDivider className={classes.divider} />
                <SttExpansionPanel
                    title={strings.substituicaoAnexos}
                    compact
                    opened={false}
                    children={
                        <div>
                            <SttNotification severity="info" className={classes.notification} onClick={() => exibirModalNovosAnexos.value = true}>
                                {strings.substituirAnexos}
                            </SttNotification>
                        </div>
                    }
                />

                <ModalNovosAnexos
                    idExame={exame.exame.id_exame}
                    arquivosExame={arquivosExame}
                    imagensAtualizadas={imagensAtualizadas}
                    exibirModalNovosAnexos={exibirModalNovosAnexos}
                />

                <SttDivider className={classes.divider} />

                <Form
                    idExame={exame.exame.id_exame}
                    idLaudoEmissao={exame.idLaudoEmissao}
                    idPaciente={exame.exame.id_paciente}
                    idSolicitante={exame.exame.id_medico_solicitante}
                    nomeSolicitante={exame.exame.nome_medico_solicitante}
                    callbackProximoExame={callbackProximoExame}
                    laudar={laudar}
                />
            </SttGrid>
        </SttGrid>
    )

}

export default Laudo;