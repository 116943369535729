import * as yup from 'yup';
import { DADOS_EXAME, GRAU_COMPARACAO_SONO_HABITUAL, GRAU_DESCONFORTO_APARELHO_POLISSONOGRAFIA, HORA_ADORMECER, QUANTIDADE_DESPERTOU_NOITE, TEMPO_DEMORA_INICIAR_SONO, TEMPO_DORMIU_NOITE } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_EXAME]: yup.object().shape({
            [GRAU_DESCONFORTO_APARELHO_POLISSONOGRAFIA]: yup.string().matches(/(N|L|M|I)/).nullable().required(strings.campoObrigatorio),
            [HORA_ADORMECER]: yup.string().nullable().test('horaMinutoInvalida', strings.horaInvalida, (val) => {
                val = val || '';
                return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
            }).test('tamanho', strings.formatosInput.validacaoHhMm, (val) => {
                val = val || '';
                return val.length === 5;
            }).required(strings.campoObrigatorio),
            [TEMPO_DEMORA_INICIAR_SONO]: yup.string().nullable().test('horaMinutoInvalida', strings.horaInvalida, (val) => {
                val = val || '';
                return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
            }).test('tamanho', strings.formatosInput.validacaoHhMm, (val) => {
                val = val || '';
                return val.length === 5;
            }).required(strings.campoObrigatorio),
            [QUANTIDADE_DESPERTOU_NOITE]: yup.number().nullable().required(strings.campoObrigatorio),
            [TEMPO_DORMIU_NOITE]: yup.string().nullable().test('horaMinutoInvalida', strings.horaInvalida, (val) => {
                val = val || '';
                return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
            }).test('tamanho', strings.formatosInput.validacaoHhMm, (val) => {
                val = val || '';
                return val.length === 5;
            }).required(strings.campoObrigatorio),
            [GRAU_COMPARACAO_SONO_HABITUAL]: yup.string().matches(/(M|P|S|E)/).nullable().required(strings.campoObrigatorio)
        })
    });
}