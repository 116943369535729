import React, { useContext } from 'react';
import { FIELDS } from './fieldNames';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { useSignalEffect } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

const Equipamento = (props) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { form, field, equipamentos, meta } = props;
    const { name, value, onBlur } = field;
    const { setFieldValue } = form;

    useSignalEffect(() => {
        if (equipamentos?.value?.length === 1) {
            setFieldValue(FIELDS.EQUIPAMENTO, equipamentos.value[0]);
        }
    });

    return (
        <SttAutocomplete
            inputprops={{
                name: name,
                label: strings.equipamento,
                required: true,
                error: meta.touched && meta.error ? meta.error : undefined
            }}
            {...field}
            getOptionLabel={option => option?.id_humano || ''}
            options={equipamentos.value}
            value={value}
            onBlur={onBlur}
            onChange={(e, item) => setFieldValue(FIELDS.EQUIPAMENTO, item || null)}
        />
    )
}

export default Equipamento;