import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../../request';
import { PERMISSOES } from '../../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttTranslateHook,
    SttDivider,
    SttText
} from '@stt-componentes/core';
import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../security/acl';
import usuario from '../../../signals/usuario';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { signal, computed, batch } from '@preact/signals-react';
import { idSolicitacaoSelecionada } from './index';
import {
    CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA,
    CONSTANTE_SOLICITACAO_POSSUI_RONCO,
    CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS
} from '../../../common/Constants';
import { enviarImagens } from '../../../signals/envio-imagens';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
    },
}));

const idComponenteEnvioImagens = signal(Math.random());
const solicitacao = signal(null);

export const dadosEnvioImagens = computed(() => {
    if (solicitacao?.value) {
        return {
            id: solicitacao.value.id_solicitacao,
            idIndicacaoPolissonografia: solicitacao.value.id_indicacao_polissonografia,
            instituicao: solicitacao.value.id_instituicao,
        }
    }
    return {};
});

const getTextoSonolencia = (valor, strings) => {
    if (valor === CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.INTENSA) {
        return strings.intensa;
    }
    if (valor === CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.LEVE) {
        return strings.leve;
    }
    if (valor === CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.MODERADA) {
        return strings.moderada;
    }
    return strings.nenhuma;
}

const getTextoRonca = (valor, strings) => {
    if (valor === CONSTANTE_SOLICITACAO_POSSUI_RONCO.DE_VEZ_ENQUANDO) {
        return strings.deVezEnquando;
    }
    if (valor === CONSTANTE_SOLICITACAO_POSSUI_RONCO.NAO) {
        return strings.naoRonco;
    }
    if (valor === CONSTANTE_SOLICITACAO_POSSUI_RONCO.NAO_SEI) {
        return strings.naoSei;
    }
    return strings.simTodasNoites;
}

const getTextoComportamentoSintoma = (valor, strings) => {
    if (valor === CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS.ALGUMAS_NOITES) {
        return strings.algumasNoites;
    }
    if (valor === CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS.NAO) {
        return strings.nao;
    }
    return strings.todasNoites;
}


const Detalhes = ({ voltar }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignalEffect(() => {
        if (idSolicitacaoSelecionada.value) {
            axios.get(`${global.gConfig.url_base_polissonografia}/solicitacao/${idSolicitacaoSelecionada.value}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;

                        if (data.demora_iniciar_sono) {
                            data.texto_iniciar_sono = `${strings.sim}, ${strings.ha} ${data.tempo_demora_iniciar_sono} ${data.descricao_demora_iniciar_sono}`;
                        } else {
                            data.texto_iniciar_sono = strings.nao;
                        }

                        if (data.possui_despertares_meio_sono) {
                            data.texto_despertares_meio_sono = `${strings.sim}, ${strings.ha} ${data.tempo_despertares_meio_sono} ${data.descricao_despertares_meio_sono}`;
                        } else {
                            data.texto_despertares_meio_sono = strings.nao;
                        }

                        if (data.desperta_cedo_demais) {
                            data.texto_desperta_cedo_demais = `${strings.sim}, ${strings.ha} ${data.tempo_desperta_cedo_demais} ${data.descricao_desperta_cedo_demais}`;
                        } else {
                            data.texto_desperta_cedo_demais = strings.nao;
                        }

                        if (data.medicamentos?.length > 0) {
                            data.texto_medicamentos = data.medicamentos.map(m => `- ${m.medicamento}; ${strings.motivo}: ${m.motivo}.`).join('<br>');
                        }

                        solicitacao.value = data;
                    }
                })
                .catch(err => console.log(err));
        }
    });

    const handleAbrirModalEnvioImagens = () => {
        idComponenteEnvioImagens.value = Math.random();
        enviarImagens.value = true;
    }

    const callbackEnvioImagens = () => {
        voltar(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => {
                    batch(() => {
                        enviarImagens.value = null;
                        solicitacao.value = null;
                    });
                    voltar();
                }}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.solicitacao}</SttHeading>
                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem title={strings.modalidade} content={solicitacao.value.modalidade} />
                                    <SttTextItem title={strings.dataSolicitacao} content={`${solicitacao.value.data_solicitacao_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem title={strings.solicitante} content={solicitacao.value.nome_solicitante} />
                                    <SttTextItem title={strings.cidadeUf} content={`${solicitacao.value.cidade} / ${solicitacao.value.uf}`} />
                                </div>
                            }
                        />

                        <SttDivider className={classes.divider} />
                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    <SttTextItem title={strings.nome} content={solicitacao.value.nome_paciente} />
                                    <SttTextItem title={strings.dataNascimento} content={solicitacao.value.data_nascimento_paciente} />
                                    <SttTextItem title={strings.idade} content={`${solicitacao.value.idade_paciente} anos`} />
                                    <SttTextItem title={strings.genero} content={solicitacao.value.sexo_paciente} />
                                    <SttTextItem title={strings.cns} content={solicitacao.value.cartao_sus} />
                                    <SttTextItem title={strings.peso} content={solicitacao.value.peso_paciente} />
                                    <SttTextItem title={strings.altura} content={solicitacao.value.altura_paciente} />
                                </div>
                            }
                        />

                        <SttDivider className={classes.divider} />
                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            children={
                                <div>
                                    <SttTextItem title={strings.demoraIniciarSono} content={solicitacao.value.texto_iniciar_sono} />
                                    <SttTextItem title={strings.variosDespertaresMeioSono} content={solicitacao.value.texto_despertares_meio_sono} />
                                    <SttTextItem title={strings.despertaCedoDemais} content={solicitacao.value.texto_desperta_cedo_demais} />
                                    <SttTextItem title={strings.tituloSenteSonolenciaDuranteDia} content={getTextoSonolencia(solicitacao.value.intensidade_sonolencia_fadiga, strings)} />
                                    <SttTextItem title={strings.tituloRonca} content={getTextoRonca(solicitacao.value.possui_ronco, strings)} />
                                    <SttTextItem title={strings.tituloRelatouDificuldadeRespirar} content={solicitacao.value.possui_dificuldade_respirar ? strings.sim : strings.nao} />
                                    <SttTextItem title={strings.movimentosRepetidosDuranteSono} content={getTextoComportamentoSintoma(solicitacao.value.possui_movimentos_repetidos_involuntarios, strings)} />
                                    <SttTextItem title={strings.desconfortoPernas} content={getTextoComportamentoSintoma(solicitacao.value.possui_desconforto_pernas, strings)} />
                                    <SttTextItem title={strings.problemasSaude} content={solicitacao.value.problemas_saude} />
                                    <SttHeading variant="h4" color="primary" className={classes.heading}>{strings.medicamentos48horas}</SttHeading>
                                    {
                                        solicitacao.value.texto_medicamentos &&
                                        <SttText
                                            variant="body2"
                                            className={classes.textoQuebraLinha}
                                            style={{ wordBreak: 'break-word' }}
                                            dangerouslySetInnerHTML={{ __html: solicitacao.value.texto_medicamentos.trim() }}
                                        />
                                    }
                                </div>
                            }
                        />
                        {
                            temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                            <SttButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleAbrirModalEnvioImagens}
                                nomarginleft="true"
                            >
                                {strings.enviarExame}
                            </SttButton>
                        }
                    </>
            }

            {
                temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens
                    modalOpen={enviarImagens.value}
                    resetFormulario={() => {
                        enviarImagens.value = false;
                    }}
                    solicitacao={dadosEnvioImagens}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                    key={idComponenteEnvioImagens}
                />
            }
        </SttContainer>
    )

}

export default Detalhes;