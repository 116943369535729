import * as yup from 'yup';
import { PERMISSOES } from '../../../common/Constants';
import { temPermissaoRBAC } from '../../../security/acl';
import { FIELDS } from './fieldNames';
import usuario from '../../../signals/usuario';

const validationDadosGerais = (strings) => {
    if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
        return yup.object().shape({
            [FIELDS.EQUIPAMENTO]: yup.object().shape({
                id: yup.number(),
                id_humano: yup.string(),
                id_modalidade: yup.number(),
                tipo: yup.string(),
                id_instituicao: yup.number()
            })
                .nullable()
                .required(strings.campoObrigatorio)

        });
    }
    return yup.object().shape({});
}

export { validationDadosGerais };