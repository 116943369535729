import * as yup from 'yup'
import {  MEDICAMENTO, MEDICAMENTOS, MOTIVO } from './fieldNames'

export default (strings) => {
    return yup.object().shape({
        [MEDICAMENTOS]: yup.array().of(
            yup.object().shape({
                [MEDICAMENTO]: yup.string().trim().required(strings.campoObrigatorio).nullable(),
                [MOTIVO]: yup.string().trim().required(strings.campoObrigatorio).nullable(),
            })
        ),
    });
}