import React, { Fragment } from 'react';
import {
    SttGrid,
    SttInput,
    SttHeading,
    SttFormControl,
    SttFormControlLabel,
    SttFormHelperText,
    SttRadioGroup,
    SttRadioButton,
    SttMaskedInput,
    SttNumberInput,
} from '@stt-componentes/core';
import { FastField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { DADOS_EXAME, ESPECIFICACAO_CAUSA_DESCONFORTO, GRAU_COMPARACAO_SONO_HABITUAL, GRAU_DESCONFORTO_APARELHO_POLISSONOGRAFIA, HORA_ADORMECER, QUANTIDADE_DESPERTOU_NOITE, TEMPO_DEMORA_INICIAR_SONO, TEMPO_DORMIU_NOITE } from './fieldNames';
import { CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL, CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO } from '../../../common/Constants';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0
    }
}));

const DadosExame = ({ strings }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <SttGrid container spacing={3} className={classes.container}>
                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.grauDesconfortoAparelhoPolissonografia}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${GRAU_DESCONFORTO_APARELHO_POLISSONOGRAFIA}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                <SttRadioGroup row>
                                    <SttFormControlLabel
                                        {...field}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.NENHUM}
                                                color="primary"
                                                checked={field.value === CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.NENHUM}
                                            />
                                        }
                                        label={strings.nenhumIncomodo}
                                    />
                                    <SttFormControlLabel
                                        {...field}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_LEVE}
                                                color="primary"
                                                checked={field.value === CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_LEVE}
                                            />
                                        }
                                        label={strings.incomodoLeve}
                                    />
                                    <SttFormControlLabel
                                        {...field}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_MODERADO}
                                                color="primary"
                                                checked={field.value === CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_MODERADO}
                                            />
                                        }
                                        label={strings.incomodoModerado}
                                    />
                                    <SttFormControlLabel
                                        {...field}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_INTENSO}
                                                color="primary"
                                                checked={field.value === CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_INTENSO}
                                            />
                                        }
                                        label={strings.incomodoIntenso}
                                    />
                                </SttRadioGroup>
                                {
                                    meta.touched && meta.error &&
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </FastField>
                </SttGrid>

                <SttGrid item xs={12} sm={12}>
                    <SttHeading variant="h4" color="primary">{strings.tituloEspecificacaoCausaDesconforto}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${ESPECIFICACAO_CAUSA_DESCONFORTO}`}>
                        {({
                            field,
                            meta
                        }) => (
                            <SttInput
                                multiline
                                rows={4}
                                {...field}
                                label={strings.causasDesconforto}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>

                <SttGrid item xs={12}>
                    <SttHeading required variant="h4" color="primary">{strings.informacoesNoiteExame}</SttHeading>
                </SttGrid>
                <SttGrid item xs={12} md={6} lg={4}>
                    <SttHeading required variant="h5" color="primary">{strings.horasDeitouDormir}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${HORA_ADORMECER}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttMaskedInput
                                {...field}
                                mask="duracaoHoraMinuto"
                                required={true}
                                label={strings.formatosInput.hhmm}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>

                <SttGrid item xs={12} md={6} lg={4}>
                    <SttHeading required variant="h5" color="primary">{strings.tempoDemorouIniciarSono}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${TEMPO_DEMORA_INICIAR_SONO}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttMaskedInput
                                {...field}
                                mask="duracaoHoraMinuto"
                                required={true}
                                label={strings.formatosInput.hhmm}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>

                <SttGrid item xs={12} md={6} lg={4}>
                    <SttHeading required variant="h5" color="primary">{strings.vezesDespertouDuranteNoite}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${QUANTIDADE_DESPERTOU_NOITE}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttNumberInput
                                inputProps={{
                                    maxLength: 2
                                }}
                                {...field}
                                label={strings.totalVezes}
                                required={true}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>

                <SttGrid item xs={12} md={6} lg={4}>
                    <SttHeading required variant="h5" color="primary">{strings.tempoAchaDormiuEstaNoite}</SttHeading>
                    <FastField name={`${DADOS_EXAME}.${TEMPO_DORMIU_NOITE}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttMaskedInput
                                {...field}
                                mask="duracaoHoraMinuto"
                                required={true}
                                label={strings.formatosInput.hhmm}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                            />
                        )}
                    </FastField>
                </SttGrid>
            </SttGrid>

            <SttGrid item xs={12}>
                <SttHeading required variant="h4" color="primary">{strings.comparadoSonoHabitualSonoNoite}</SttHeading>
                <FastField name={`${DADOS_EXAME}.${GRAU_COMPARACAO_SONO_HABITUAL}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttRadioGroup row>
                                <SttFormControlLabel
                                    {...field}
                                    control={
                                        <SttRadioButton
                                            type="radio"
                                            value={CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MUITO_PIOR}
                                            color="primary"
                                            checked={field.value === CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MUITO_PIOR}
                                        />
                                    }
                                    label={strings.muitoPior}
                                />
                                <SttFormControlLabel
                                    {...field}
                                    control={
                                        <SttRadioButton
                                            type="radio"
                                            value={CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.PIOR}
                                            color="primary"
                                            checked={field.value === CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.PIOR}
                                        />
                                    }
                                    label={strings.pior}
                                />
                                <SttFormControlLabel
                                    {...field}
                                    control={
                                        <SttRadioButton
                                            type="radio"
                                            value={CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.SEMELHANTE}
                                            color="primary"
                                            checked={field.value === CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.SEMELHANTE}
                                        />
                                    }
                                    label={strings.semelhante}
                                />
                                <SttFormControlLabel
                                    {...field}
                                    control={
                                        <SttRadioButton
                                            type="radio"
                                            value={CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MELHOR}
                                            color="primary"
                                            checked={field.value === CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MELHOR}
                                        />
                                    }
                                    label={strings.melhor}
                                />
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error &&
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </FastField>
            </SttGrid>
        </Fragment>
    );
}

export default DadosExame;