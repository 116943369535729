import * as yup from 'yup';
import {
    DESCRICAO_ESTUDO, INDICE_EVENTOS_RESPIRATORIOS, SPO2, TAXA_IER,
} from './fieldNames';

export default (strings) => {
    let schema = yup.object().shape({
        [DESCRICAO_ESTUDO]: yup
            .string()
            .trim()
            .nullable(),
        [TAXA_IER]: yup.number().nullable().required(strings.campoObrigatorio),
        [SPO2]: yup.number().nullable().required(strings.campoObrigatorio),
        [INDICE_EVENTOS_RESPIRATORIOS]: yup.string().nullable().required(strings.campoObrigatorio)
    });

    return schema;
}