import React, { memo, useContext } from 'react';
import { FastField, useFormikContext } from 'formik';
import {
    INDICE_EVENTOS_RESPIRATORIOS,
    OBSERVACAO,
    SPO2,
    TAXA_IER
} from './fieldNames';
import {
    SttHeading,
    SttInput,
    SttTranslateHook,
    SttFormControl,
    SttRadioGroup,
    SttFormControlLabel,
    SttRadioButton,
    SttNumberInput,
    SttGrid,
    SttFormHelperText
} from '@stt-componentes/core';
import { INDICES_EVENTOS_RESPIRATORIOS } from '../../common/Constants';

const AbaTextual = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue, values } = useFormikContext();

    return (
        <>
            <SttHeading required variant="h4" color="primary">{strings.indiceEventosRespiratorios}</SttHeading>
            <FastField name={INDICE_EVENTOS_RESPIRATORIOS}>
                {({
                    field,
                    meta,
                }) => (
                    <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined" fullWidth>
                        <SttRadioGroup
                            onChange={(e, item) => {
                                setFieldValue(INDICE_EVENTOS_RESPIRATORIOS, item);
                                setFieldValue(TAXA_IER, '');
                                setFieldValue(SPO2, '');
                            }
                            }>
                            {
                                INDICES_EVENTOS_RESPIRATORIOS.map(value => (
                                    <SttFormControlLabel
                                        {...field}
                                        control={
                                            <SttRadioButton
                                                type="radio"
                                                value={value}
                                                color="primary"
                                                checked={field.value === value}
                                            />
                                        }
                                        label={value}
                                    />
                                ))
                            }

                        </SttRadioGroup>
                        {
                            meta.touched && meta.error &&
                            <SttFormHelperText error>
                                {meta.error}
                            </SttFormHelperText>
                        }
                    </SttFormControl>
                )}
            </FastField>
            <br />
            {
                values[INDICE_EVENTOS_RESPIRATORIOS] &&
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12} sm={6}>
                        <FastField name={TAXA_IER}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttNumberInput
                                    inputProps={{
                                        maxLength: 3
                                    }}
                                    {...field}
                                    label={strings.ierHora}
                                    required={true}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>

                    <SttGrid item xs={12} sm={6}>
                        <FastField name={SPO2}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttNumberInput
                                    inputProps={{
                                        maxLength: 2
                                    }}
                                    {...field}
                                    label={strings.spo2Porcentagem}
                                    required={true}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                </SttGrid>

            }

            <SttHeading variant="h4" color="primary">{strings.observacao}</SttHeading>
            <FastField name={OBSERVACAO}>
                {({
                    field,
                    meta
                }) => (
                    <SttInput
                        multiline
                        minRows={5}
                        {...field}
                        error={meta.touched && meta.error ? true : false}
                        helperText={meta.touched && meta.error ? meta.error : undefined}
                    />
                )}
            </FastField>
        </>
    )
}

export default memo(AbaTextual);