import React, { useContext, useRef } from 'react';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import axios from 'axios';
import {
    SttModal,
    SttButton,
    SttLoading,
    SttTranslateHook,
    SttGrid
} from '@stt-componentes/core';
import { Formik } from 'formik';
import Anexos from '../envio-imagens/anexos';
import { makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import { anexos } from '../../signals/envio-imagens';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        'anexo': yup
            .array()
            .min(1)
            .of(
                yup.object()
                    .nullable()
            )
            .required(strings.campoObrigatorio)
    });
}

const initialValues = {
    anexo: [{}]
}

const ModalNovosAnexos = ({ idExame, arquivosExame, exibirModalNovosAnexos, imagensAtualizadas }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const schema = validationSchema(strings);
    const formRef = useRef();

    const fecharModal = () => {
        exibirModalNovosAnexos.value = false;
        anexos.value = [];
    }

    return (
        <SttModal
            title={strings.substituicaoAnexos}
            open={exibirModalNovosAnexos.value}
            outModalClose={fecharModal}
            iconClose={fecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(true);
                        const formData = new FormData();
                        formData.append('idExame', idExame);

                        dados.anexo.forEach((a, index) => {
                            if (a && (a instanceof File)) {
                                formData.append(`nome_anexos.${index}`, a.name);
                                formData.append(`anexo.${index}`, a);
                            }
                        });

                        const alertConfig = {};
                        axios.post(`${global.gConfig.url_base_polissonografia}/exame-anexo`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                            .then((response) => {
                                if (response.status === HttpStatus.CREATED) {
                                    alertConfig.title = strings.sucesso;
                                    alertConfig.message = strings.anexosInseridosSucesso;
                                    alertConfig.type = 'success';
                                    alertConfig.open = true;
                                    alertConfig.onClose = () => {
                                        arquivosExame.value = [...response.data.data.anexos.arquivos];
                                        fecharModal();
                                        imagensAtualizadas.value = false;
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    }
                                    alertConfig.options = [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                arquivosExame.value = [...response.data.data.anexos.arquivos];
                                                fecharModal();
                                                imagensAtualizadas.value = false;
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                }
                                            }

                                        }
                                    ]
                                    alerta.value = alertConfig;
                                } else {
                                    alertConfig.title = strings.erro;
                                    alertConfig.message = strings.mensagemErro;
                                    alertConfig.type = 'error';
                                    alertConfig.open = true;
                                    alertConfig.options = [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                }
                                            }

                                        }
                                    ];
                                    alertConfig.onClose = () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    };
                                }
                            })
                            .catch(err => {
                                const { response } = err;
                                let msg = strings.mensagemErroGeral;
                                let titulo = strings.erro;
                                let msgAlerta = '';

                                if (response) {
                                    if (response.status === HttpStatus.BAD_REQUEST) {
                                        const dadosResp = response.data;
                                        let arrMensagem = [];
                                        dadosResp.errors.forEach(error => {
                                            arrMensagem.push(`- ${error.message}`);
                                        });
                                        msg = arrMensagem.join('\n');
                                        msgAlerta = msg;
                                    } else {
                                        msgAlerta = msg;
                                    }
                                } else {
                                    msgAlerta = msg;
                                }
                                alertConfig.title = titulo;
                                alertConfig.message = msgAlerta;
                                alertConfig.type = 'error';
                                alertConfig.open = true;
                                alertConfig.options = [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            }
                                        }

                                    }
                                ];
                                alertConfig.onClose = () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                };
                            })
                            .finally(() => {
                                setSubmitting(false);
                                alerta.value = alertConfig;
                            });
                    }}
                >
                    {
                        ({ handleSubmit, isSubmitting }) => {
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <SttGrid container spacing={3} className={classes.container}>
                                        <SttGrid item xs={12}>
                                            <Anexos exibirAviso={false} exibirAnexoExterno={false} />
                                        </SttGrid>
                                    </SttGrid>
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoMensagemEspera}
                                    />
                                </form>
                            )
                        }
                    }
                </Formik>
            }
            footer={
                <div>
                    <SttButton variant="contained" color="primary" onClick={() => formRef.current.submitForm()}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="outlined" color="primary" onClick={fecharModal}>
                        {strings.cancelar}
                    </SttButton>
                </div>
            }
        />
    );
}

export default ModalNovosAnexos;