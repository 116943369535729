export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    MEDICO_EXECUTOR: 'medicoExecutor',
    ADMINISTRADOR: 'administrador',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR_REDE: 'visualizadorRede',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
};

export const PERMISSOES = {
    ADMINISTRATIVO: 'POLISSONOGRAFIA_ADMINISTRATIVO',
    CRIAR_EXAME: 'POLISSONOGRAFIA_CRIAR_EXAME',
    POLISSONOGRAFIA: 'POLISSONOGRAFIA_POLISSONOGRAFIA',
    INVALIDAR_EXAME: 'POLISSONOGRAFIA_INVALIDAR_EXAME',
    INVALIDAR_EXAME_LAUDADO: 'POLISSONOGRAFIA_INVALIDAR_EXAME_LAUD',
    LAUDAR_EXAME: 'POLISSONOGRAFIA_LAUDAR_EXAME',
    LAUDAR_EXAME_FABRICA: 'POLISSONOGRAFIA_LAUDAR_EXAME_FABRICA',
    PRIORIZAR_EXAME: 'POLISSONOGRAFIA_PRIORIZAR_EXAME',
    SOLICITAR_EXAME: 'POLISSONOGRAFIA_SOLICITAR_EXAME',
    TROCAR_REDE: 'POLISSONOGRAFIA_TROCAR_REDE',
    VISUALIZAR_EXAME: 'POLISSONOGRAFIA_VISUALIZACAO',
    VISUALIZAR_IMAGEM: 'POLISSONOGRAFIA_VISUALIZAR_IMAGEM',
    VISUALIZAR_LAUDO: 'POLISSONOGRAFIA_VISUALIZAR_LAUDO',
    VISUALIZAR_SOLICITACAO: 'POLISSONOGRAFIA_VISUALIZAR_SOLIC_EX'
};

export const MODALIDADE = {
    SIGLA: 'PSG',
    DESCRICAO: 'Polysomnography',
    CODIGO: '1.2.826.0.1.3680043.8.213.53',
    DESCRICAO_PT: 'Polissonografia'
};

export const ORIGEM_MANUAL = 'M';

export const CONSELHO_TRABALHO = {
    CRM: 'CRM'
}

export const TIPO_ANEXO = {
    PDF: 'pdf',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    BMP: 'bmp'
}

export const CONSTANTE_DESCRICAO_SOLICITACAO_SENTE_SONOLENCIA = (strings, constante) => {
    switch (constante) {
        case CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.NENHUMA:
            return strings.nenhuma;

        case CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.LEVE:
            return strings.leve;

        case CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.MODERADA:
            return strings.moderada;

        case CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA.INTENSA:
            return strings.intensa;
    }
}

export const CONSTANTE_DESCRICAO_SOLICITACAO_POSSUI_RONCO = (strings, constante) => {
    switch (constante) {
        case CONSTANTE_SOLICITACAO_POSSUI_RONCO.SIM_TODAS_NOITES:
            return strings.simTodasNoites;

        case CONSTANTE_SOLICITACAO_POSSUI_RONCO.DE_VEZ_ENQUANDO:
            return strings.deVezEnquando;

        case CONSTANTE_SOLICITACAO_POSSUI_RONCO.NAO:
            return strings.naoRonco;

        case CONSTANTE_SOLICITACAO_POSSUI_RONCO.NAO_SEI:
            return strings.naoSei;
    }
}

export const CONSTANTE_DESCRICAO_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL = (strings, constante) => {
    switch (constante) {
        case CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MUITO_PIOR:
            return strings.muitoPior;

        case CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.PIOR:
            return strings.pior;

        case CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.SEMELHANTE:
            return strings.semelhante;

        case CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL.MELHOR:
            return strings.melhor;
    }
}

export const CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS = (strings, constante) => {
    switch (constante) {
        case CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS.NAO:
            return strings.nao;

        case CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS.TODAS_NOITES:
            return strings.todasNoites;

        case CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS.ALGUMAS_NOITES:
            return strings.algumasNoites;
    }
}

export const CONSTANTE_DESCRICAO_ENVIO_EXAMES_GRAU_DESCONFORTO = (strings, constante) => {
    switch (constante) {
        case CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.NENHUM:
            return strings.nenhum;

        case CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_LEVE:
            return strings.incomodoLeve;

        case CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_MODERADO:
            return strings.incomodoModerado;

        case CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO.INCOMODO_INTENSO:
            return strings.incomodoIntenso;
    }
}

export const CONSTANTE_SOLICITACAO_SENTE_SONOLENCIA = {
    NENHUMA: 'N',
    LEVE: 'L',
    MODERADA: 'M',
    INTENSA: 'I'
}

export const CONSTANTE_SOLICITACAO_POSSUI_RONCO = {
    SIM_TODAS_NOITES: 'S',
    DE_VEZ_ENQUANDO: 'D',
    NAO: 'N',
    NAO_SEI: 'O'
}

export const CONSTANTE_SOLICITACAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS = {
    NAO: 'N',
    TODAS_NOITES: 'T',
    ALGUMAS_NOITES: 'A'
}

export const CONSTANTE_ENVIO_EXAMES_GRAU_DESCONFORTO = {
    NENHUM: 'N',
    INCOMODO_LEVE: 'L',
    INCOMODO_MODERADO: 'M',
    INCOMODO_INTENSO: 'I'
}

export const CONSTANTE_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL = {
    MUITO_PIOR: 'M',
    PIOR: 'P',
    SEMELHANTE: 'S',
    MELHOR: 'E'
}

export const CONSTANTE_NAO = 'N';

export const CONSTANTE_SIM = 'S';

export const INDICES_EVENTOS_RESPIRATORIOS = [
    'Dentro da normalidade',
    'Aumentado em grau leve',
    'Aumentado em grau moderado',
    'Aumentado em grau acentuado'
];