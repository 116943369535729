import React, { useContext, useState } from 'react';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import {
    SttModal,
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import { useSignals } from '@preact/signals-react/runtime';

const ConfirmarLaudo = (props) => {
    useSignals();
    const { html, callbackConfirmar, callbackCancelar, modalAberto, idExame } = props;

    const { values, resetForm } = useFormikContext();

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [confirmando, setConfirmando] = useState(false);
    const [assinando, setAssinando] = useState(false);

    const confirmar = () => {
        setConfirmando(true);
        axios.post(`${global.gConfig.url_base_polissonografia}/laudo/confirmar`, values, { headers: getHeaders() })
            .then((response) => {
                resetForm();

                if (usuario.value.cpf && usuario.value.idufsc) {
                    const urlAuthAssinaturaDigital = `${global.gConfig.url_base_assinatura_digital_ufsc}?cpfAssinador=${usuario.value.cpf}`;
                    let win = window.open(urlAuthAssinaturaDigital, "_blank", "height=800, width=1000;");
                    let timer = setInterval(() => {
                        if (win.closed) {
                            clearInterval(timer);
                            setAssinando(true);
                            let alertConfig = {
                                open: true,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            alerta.value = { ...alerta.value, open: false };
                                            callbackConfirmar();
                                        }
                                    }
                                ],
                                onClose: () => {
                                    alerta.value = { ...alerta.value, open: false };
                                    callbackConfirmar();
                                }
                            };
                            axios.post(`${global.gConfig.url_base_exames}/assinatura-laudo`, { exame: idExame }, { headers: getHeaders() })
                                .then((res) => {
                                    alertConfig = {
                                        title: strings.sucesso,
                                        message: strings.laudoAssinado,
                                        type: 'success'
                                    };
                                })
                                .catch(err => {
                                    alertConfig = {
                                        title: strings.erro,
                                        message: strings.erroAssinatura,
                                        type: 'error'
                                    };
                                })
                                .finally(() => {
                                    alerta.value = alertConfig;
                                    setAssinando(false);
                                });
                        }
                    }, 1500);
                } else {
                    callbackConfirmar();
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                const alertConfig = {
                    title: strings.erro,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                // dispatch(open(false));
                            }
                        }
                    ],
                    onClose: () => {
                        // dispatch(open(false));
                    }
                };

                if (response && (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR)) {
                    let arrMensagem = [];
                    response.data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                    alertConfig.titulo = response.data.message;
                    alertConfig.message = msg;
                }
                // dispatch(configure(alertConfig));
            })
            .finally(() => {
                setConfirmando(false);
            });
    }

    return (
        <>
            <SttModal
                title={strings.resumoLaudo}
                open={modalAberto}
                outModalClose={callbackCancelar}
                iconClose={callbackCancelar}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={confirmando} onClick={confirmar}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={confirmando}
                text={strings.confirmando}
            />
            <SttLoading
                open={assinando}
                text={strings.assinando}
            />
        </>
    );
}

ConfirmarLaudo.propTypes = {
    callbackConfirmar: PropTypes.func.isRequired,
    callbackCancelar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ConfirmarLaudo;