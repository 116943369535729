import React, { useContext } from 'react';
import {
    SttGrid,
    SttInput,
    SttTranslateHook
} from '@stt-componentes/core';
import { FIELDS } from './fieldNames';
import { Field, FastField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Equipamento from '../informacoes-gerais/equipamento';
import { temPerfilRBAC } from '../../../security/acl';
import { PERFIL } from '../../../common/Constants';
import usuario from '../../../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const DadosGerais = ({ equipamentos }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <>
            <SttGrid container spacing={2} className={classes.container}>
                <SttGrid item xs={12} md={8}>
                    <Field name={FIELDS.EQUIPAMENTO}>
                        {(props) => (
                            <Equipamento equipamentos={equipamentos} strings={strings} {...props} />
                        )}
                    </Field>
                </SttGrid>
                {
                    !temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE) &&
                    <SttGrid item xs={12} sm={12}>
                        <FastField name={FIELDS.OBSERVACAO}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttInput
                                    multiline
                                    minRows={5}
                                    {...field}
                                    label={strings.observacoes}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                }
            </SttGrid>
        </>
    );
}

export default DadosGerais;