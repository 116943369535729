import React, { useRef, memo, lazy, Suspense, useContext } from 'react';
import { Formik } from 'formik';
import Paciente from '@stt-componentes/paciente';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import initialValues from './initialValues';
import { makeStyles } from '@material-ui/core/styles';
import { temPermissaoRBAC } from '../../security/acl';
import usuario from '../../signals/usuario';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import { batch } from '@preact/signals-react';
import { PERMISSOES } from '../../common/Constants';
import { CAMPOS_PACIENTE } from './camposPaciente';
import EnvioImagens from '../../componentes/envio-imagens/index.js';
import { enviarImagens } from '../../signals/envio-imagens';

const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const IndicacaoClinica = lazy(() => import('../../componentes/solicitacao/indicacaoClinica'));
const Medicamentos = lazy(() => import('../../componentes/solicitacao/medicamentos'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    }
}));

const campos = CAMPOS_PACIENTE;

const Solicitacao = () => {
    useSignals();

    const confirmarEnvioExame = useSignal(false);
    const confirmarTermo = useSignal(false);
    const gerarTermo = useSignal(false);
    const solicitacao = useSignal(null);
    const instituicao = useSignal(null);
    const paciente = useSignal(null);
    const secoesAbertas = useSignal({
        'paciente': true,
        'solicitante': false,
        'indicacaoClinica': false,
        'medicamentos': false
    });

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const PSG_API_BASE_URL = global.gConfig.url_base_polissonografia;
    const schema = validationSchema(strings, campos.camposCadastro);

    const handleFinalizarEnvioImagens = () => {
        solicitacao.value = null;
        instituicao.value = null;
        paciente.value = null;
    }

    const handleAbrirModalEnvioImagens = () => {
        enviarImagens.value = temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME);
    }

    // Seções da solicitação
    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoIndicacaoClinica = useRef(null);
    const secaoMedicamentos = useRef(null);

    /**
     * Gera o termo de autorização
     * 
     * @param {number} idPaciente
     */
    const imprimirTermo = () => {
        let tab = window.open();
        if (tab) {
            tab.document.write('<p>Aguarde...</p>');
            const idBuffer = Buffer.from(paciente.value.toString());
            axios.get(`${PSG_API_BASE_URL}/termo-autorizacao/${idBuffer.toString('base64')}/uf/${instituicao.value.uf}`, { headers: getHeaders(), responseType: 'blob' })
                .then((response) => {
                    if (response.data) {
                        const fileURL = URL.createObjectURL(response.data);
                        tab.location = fileURL;
                    }
                })
                .catch(err => {
                    console.log(err);
                    alerta.value = {
                        ...alerta.value,
                        type: strings.erro,
                        message: strings.erroGerarTermo,
                        type: 'error',
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }
                        ],
                        open: true
                    }
                })
                .finally(() => {
                    batch(() => {
                        gerarTermo.value = false;
                        paciente.value = null;
                        instituicao.value = null;
                    });
                });
        }
    }

    useSignalEffect(() => {
        if (gerarTermo.value) {
            imprimirTermo();
        } else {
            batch(() => {
                paciente.value = null;
                instituicao.value = false;
            });
        }
    })

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas.value
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        secoesAbertas.value = novoSecoesAbertas;
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno['paciente']) {
                abrirSecao('paciente', true);
                node = secaoPaciente.current;
            } else if (retorno['solicitante']) {
                abrirSecao('solicitante', true);
                node = secaoSolicitante.current;
            } else if (retorno['indicacaoClinica']) {
                abrirSecao('indicacaoClinica', true);
                node = secaoIndicacaoClinica.current;
            } else if (retorno['medicamentos']) {
                abrirSecao('medicamentos', true);
                node = secaoMedicamentos.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
        });
    }

    const enviarSolicitacao = (dados, setSubmitting, resetForm) => {
        dados.digitador = {
            id: usuario.value.idFuncionario
        }
        if (dados.paciente) {
            if (dados.paciente.cpf) {
                dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
            }
            if (dados.paciente.cep) {
                dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
            }
        }

        if (dados.solicitante?.medico?.id === -1) {
            dados.solicitante.medico = dados.solicitante.outroMedico;
        }

        if (dados.paciente) {
            if (dados.paciente.profissao) {
                dados.paciente.profissao = dados.paciente.profissao.trim();
            }
        }

        let tipoAlertaSolicitacao = '';
        let tituloAlertaSolicitacao = '';
        let mensagemAlertaSolicitacao = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${PSG_API_BASE_URL}/solicitacao`, dados, { headers: getHeaders() })
            .then((response) => {
                const dadosSolicitacao = response.data.data;
                const dadosPaciente = dadosSolicitacao.paciente;

                solicitacao.value = { id: dadosSolicitacao.idSolicitacao, ...dadosSolicitacao };
                paciente.value = dadosPaciente;
                instituicao.value = dados.solicitante.instituicao;
                abrirSecao('paciente', true);

                tipoAlertaSolicitacao = 'success';

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        confirmarTermo.value = true;
                    }
                }];

                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                    confirmarTermo.value = true;
                }

                tituloAlertaSolicitacao = strings.sucesso;
                mensagemAlertaSolicitacao = response.data.message;

                resetForm();
            })
            .catch(err => {
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResposta = response.data;
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        tipoAlertaSolicitacao = 'error';
                        tituloAlertaSolicitacao = dadosResposta.message;
                        mensagemAlertaSolicitacao = msg;
                    } else {
                        tipoAlertaSolicitacao = 'error';
                        tituloAlertaSolicitacao = strings.erro;
                        mensagemAlertaSolicitacao = msg;
                    }
                } else {
                    tipoAlertaSolicitacao = 'error';
                    tituloAlertaSolicitacao = strings.erro;
                    mensagemAlertaSolicitacao = msg;
                }
                options = [
                    {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }
                ];

                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlertaSolicitacao,
                    title: tituloAlertaSolicitacao,
                    message: mensagemAlertaSolicitacao,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={(data, { setFieldValue, setSubmitting, resetForm }) => {
                    setSubmitting(false);

                    let dados = JSON.parse(JSON.stringify(data));
                    if (dados.indicacaoClinica?.idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono) {
                        dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono = dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDemoraIniciarSono.id;
                    }

                    if (dados.indicacaoClinica?.idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon) {
                        dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon = dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDespertaresMeioSon.id;
                    }

                    if (dados.indicacaoClinica?.idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais) {
                        dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais = dados.indicacaoClinica.idIndicacaoPolissonoEspecificacaoTempoDespertaCedoDemais.id;
                    }

                    alerta.value = {
                        ...alerta.value,
                        open: true,
                        title: strings.confirmacao,
                        type: 'alert',
                        message: strings.mensagemConfirmacaoEnvioSolicitacao,
                        options: [{
                            title: strings.sim,
                            onClick: () => {
                                setSubmitting(true);
                                enviarSolicitacao(dados, setSubmitting, resetForm, setFieldValue);
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        },
                        {
                            title: strings.nao,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        }],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    };
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        validateForm,
                        setFieldValue,
                        submitCount
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    {/* Dados do paciente */}
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secoesAbertas.value['paciente']}
                                        callback={estadoInterno => abrirSecao('paciente', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    campos={campos}
                                                    imc
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }}
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    {/* Solicitante */}
                                    <SttExpansionPanel
                                        title={strings.medicoSolicitante}
                                        opened={secoesAbertas.value['solicitante']}
                                        callback={estadoInterno => abrirSecao('solicitante', estadoInterno)}
                                        classegriditem={classes.fullWidth}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante strings={strings} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    {/* Indicação clínica */}
                                    <SttExpansionPanel
                                        title={strings.indicacaoClinica}
                                        opened={secoesAbertas.value['indicacaoClinica']}
                                        callback={estadoInterno => abrirSecao('indicacaoClinica', estadoInterno)}
                                        classegriditem={classes.fullWidth}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <IndicacaoClinica />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    {/* Medicamentos */}
                                    <SttExpansionPanel
                                        title={strings.medicamentos}
                                        opened={secoesAbertas.value['medicamentos']}
                                        callback={estadoInterno => abrirSecao('medicamentos', estadoInterno)}
                                        classegriditem={classes.fullWidth}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Medicamentos />
                                            </Suspense>
                                        }
                                    />
                                    <br />
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => verificarSecoesComErro(validateForm)}
                                    >
                                        {strings.enviar}
                                    </SttButton>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                                <Alerta
                                    open={confirmarTermo?.value}
                                    title={strings.termoEsclarecimento}
                                    message={strings.mensagemConfirmarTermo_solicitacao}
                                    type={'confirmation'}
                                    options={[
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                confirmarTermo.value = false;
                                                gerarTermo.value = true;
                                                if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                                                    confirmarEnvioExame.value = true;
                                                }
                                            }
                                        },

                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                confirmarTermo.value = false;
                                                if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                                                    confirmarEnvioExame.value = true;
                                                }
                                            }
                                        }
                                    ]}
                                    onClose={() => {
                                        confirmarTermo.value = false;
                                        if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                                            confirmarEnvioExame.value = true;
                                        }
                                    }}
                                />
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                                    <Alerta
                                        open={confirmarEnvioExame.value}
                                        title={strings.tituloConfirmarEnvioExame}
                                        message={strings.mensagemConfirmarEnvioExame}
                                        type={'confirmation'}
                                        options={[
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    enviarImagens.value = true;
                                                    confirmarEnvioExame.value = false;
                                                    handleAbrirModalEnvioImagens();
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    solicitacao.value = false;
                                                    confirmarEnvioExame.value = false;
                                                }
                                            }
                                        ]}
                                        onClose={() => {
                                            solicitacao.value = false;
                                            confirmarEnvioExame.value = false;
                                        }}
                                    />
                                }
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) && solicitacao.value && enviarImagens.value &&
                <EnvioImagens
                    modalOpen={enviarImagens.value}
                    resetFormulario={() => {
                        solicitacao.value = false;
                        enviarImagens.value = false;
                    }}
                    solicitacao={solicitacao}
                    callbackFinalizarEnvio={handleFinalizarEnvioImagens}
                />
            }
        </>
    );
};

export default Solicitacao;