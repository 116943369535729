import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import axios from 'axios';
import { SttLoading, SttAlerta, SttGrid, SttButton, SttModal, SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { listar } from '../../signals/exame';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const TrocarRede = (props) => {
    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { redes, trocarRede, exame, redeExameSelecionado } = props;
    const classes = useStyles();

    const rede = useSignal(null);
    const confirmarTrocarRede = useSignal(false);
    const trocandoRede = useSignal(false);
    const alerta = useSignal(false);
    const mensagemAlerta = useSignal('');
    const tipoAlerta = useSignal('success');
    const tituloAlerta = useSignal(strings.sucesso);
    const opcoesAlerta = useSignal([]);
    const onCloseAlerta = useSignal(() => {
        alerta.value = false;
        listar.value = true;
        fecharTrocarRede();
    });
    
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const fecharTrocarRede = () => {
        rede.value = null;
        trocarRede.value = false;
    }

    const selecionarRede = (event, novaRede) => {
        rede.value = novaRede;
    }

    const trocarExame = () => {
        confirmarTrocarRede.value = false;
        trocarRede.value = true;

        const dados = { exame, rede: rede.value.id };

        axios
            .put(`${EXAME_API_BASE_URL}/trocar-rede`, dados, { headers: getHeaders() })
            .then((resposta) => {
                tipoAlerta.value = 'success';
                mensagemAlerta.value = strings.redeAlterada;
                alerta.value = true;
                opcoesAlerta.value = ([
                    {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = false;
                            listar.value = true;
                            fecharTrocarRede();
                        }
                    }
                ]);
            })
            .catch(err => {
                trocarRede.value = false;
                console.log(err);
                tipoAlerta.value = 'error';
                tituloAlerta.value = strings.erro;
                mensagemAlerta.value = strings.erroTrocarRedeExame;
                opcoesAlerta.value = ([{ title: strings.ok, onClick: () => alerta.value = false }]);
                alerta.value = true;
            });
    }

    return (
        <>
            <SttModal
                title={strings.trocarRede}
                open={trocarRede.value}
                outModalClose={fecharTrocarRede}
                iconClose={fecharTrocarRede}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.novaRede,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={redes.filter(r => r.id !== redeExameSelecionado)}
                                    value={rede.value}
                                    onChange={selecionarRede}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={!rede.value} onClick={() => confirmarTrocarRede.value = true}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharTrocarRede}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttAlerta
                open={confirmarTrocarRede.value}
                title={strings.atencao}
                message={strings.confirmarTrocarRedeExame}
                type={'alert'}
                onClose={() => {
                    confirmarTrocarRede.value = false;
                }}
                options={
                    [
                        {
                            title: strings.sim,
                            onClick: trocarExame
                        },

                        {
                            title: strings.nao,
                            onClick: () => {
                                confirmarTrocarRede.value = false;
                            }
                        }
                    ]
                }
            />
            <SttAlerta
                open={alerta.value}
                title={tituloAlerta.value}
                message={mensagemAlerta.value}
                type={tipoAlerta.value}
                options={opcoesAlerta.value}
                onClose={onCloseAlerta.value}
            />

            <div className={classes.carregando}>
                <SttLoading
                    open={trocandoRede.value}
                    text={strings.trocandoRede}
                />
            </div>
        </>
    );
}
export default TrocarRede;
