import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { getHeaders } from '../../request';
import Imagens from '../anexos/imagens';
import { SITUACAO_LAUDO } from './constantes';
import { temPermissaoRBAC, temPermissaoRede } from '../../security/acl';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import { CONSTANTE_DESCRICAO_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL, CONSTANTE_DESCRICAO_ENVIO_EXAMES_GRAU_DESCONFORTO, CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS, CONSTANTE_DESCRICAO_SOLICITACAO_POSSUI_RONCO, CONSTANTE_DESCRICAO_SOLICITACAO_SENTE_SONOLENCIA, PERMISSOES } from '../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttGrid,
    SttNotification,
    SttAlertTitle,
    SttTranslateHook,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableBody,
    SttText
} from '@stt-componentes/core';
import usuario from '../../signals/usuario';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const Visualizacao = (props) => {
    useSignals();

    const { imprimirFn, imprimirProtocoloFn, imprimirTermoFn } = props;

    const idExame = useSignal(null);
    const exame = useSignal(null);
    const anexosExame = useSignal(null);
    const priorizar = useSignal(false);
    const invalidar = useSignal(false);
    const permissaoBtns = useSignal([]);

    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const POLISSONO_API_BASE_URL = global.gConfig.url_base_polissonografia;

    useEffect(() => {
        if (id) {
            navigate('/exames', { replace: true });
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }
    }, []);

    useSignalEffect(() => {
        if (usuario.value.perfisRBAC && exame.value) {
            let rede;
            usuario.value.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.value.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                permissaoBtns.value = [
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ];
            }
        }
    });

    useEffect(() => { //effect?
        if (location.state?.exame) {
            idExame.value = location.state.exame;
        }
    }, [location]);

    useSignalEffect(() => {
        if (idExame?.value) {
            axios
                .get(`${POLISSONO_API_BASE_URL}/exame/${idExame.value}`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    exame.value = data;
                    anexosExame.value = data.arquivos;
                })
                .catch(err => {
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let titulo = strings.erro;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        titulo = data.message;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    const alertConfig = {
                        title: titulo,
                        message: msg,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    };
                                    voltar();
                                }
                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                            voltar();
                        }
                    };
                    alerta.value = alertConfig;
                });
        }
    })

    const voltar = () => {
        navigate('/exames', { state: { from: 'visualizar' } });
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !exame.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{exame.value.exame.descricao_exame}</SttHeading>

                        <SttExpansionPanel
                            title={strings.paciente}
                            children={
                                <div>
                                    <SttTextItem title={strings.nome} content={exame.value.exame.nome_paciente} />
                                    <SttTextItem title={strings.dataNascimento} content={exame.value.exame.data_nascimento_paciente_formatada} />
                                    <SttTextItem title={strings.sexo} content={exame.value.exame.sexo_paciente} />
                                    {exame.value.indicacao?.peso_paciente && <SttTextItem title={strings.peso} content={`${exame.value.indicacao.peso_paciente} kg`} />}
                                    {exame.value.indicacao?.altura_paciente && <SttTextItem title={strings.altura} content={`${exame.value.indicacao.altura_paciente} cm`} />}
                                    <SttTextItem title={strings.idade} content={`${exame.value.exame.idade_paciente} anos`} />
                                    {exame.value.exame.cartao_sus_paciente && <SttTextItem title={strings.cns} content={exame.value.exame.cartao_sus_paciente} />}
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosExame}
                            children={
                                <div>
                                    <SttTextItem title={strings.modalidade} content={exame.value.exame.descricao_modalidade} />
                                    <SttTextItem title={strings.dataExame} content={`${exame.value.exame.data_exame} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem title={strings.protocolo} content={exame.value.exame.protocolo_rctm} />
                                    <SttTextItem title={strings.requisicao} content={exame.value.exame.requisicao} />
                                    <SttTextItem title={strings.instituicao} content={exame.value.exame.nome_instituicao} />
                                    <SttTextItem title={strings.medicoSolicitante} content={exame.value.exame.nome_medico_solicitante} />
                                    {exame.value.exame.nome_medico_executor && <SttTextItem title={strings.medicoExecutor} content={exame.value.exame.nome_medico_executor} />}
                                </div>
                            }
                        />

                        {
                            exame.value.indicacao &&
                            <SttExpansionPanel
                                title={strings.indicacaoClinica}
                                children={
                                    <div>
                                        {exame.value.indicacao.demora_iniciar_sono !== null && <SttTextItem title={strings.demoraIniciarSono} content={exame.value.indicacao.demora_iniciar_sono ? `${strings.sim}, nos(as) últimos(as) ${exame.value.indicacao.tempo_demora_iniciar_sono} ${exame.value.indicacao.especificacao_tempo_demora_iniciar_sono}` : strings.nao} />}
                                        {exame.value.indicacao.possui_despertares_meio_sono !== null && <SttTextItem title={strings.variosDespertaresMeioSono} content={exame.value.indicacao.possui_despertares_meio_sono ? `${strings.sim}, nos(as) últimos(as) ${exame.value.indicacao.tempo_despertares_meio_sono} ${exame.value.indicacao.especificacao_tempo_despertares_meio_sono}` : strings.nao} />}
                                        {exame.value.indicacao.desperta_cedo_demais !== null && <SttTextItem title={strings.despertaCedoDemais} content={exame.value.indicacao.desperta_cedo_demais ? `${strings.sim}, nos(as) últimos(as) ${exame.value.indicacao.tempo_desperta_cedo_demais} ${exame.value.indicacao.especificacao_tempo_desperta_cedo_demais}` : strings.nao} />}
                                        {exame.value.indicacao.intensidade_sonolencia_fadiga && <SttTextItem title={strings.tituloSenteSonolenciaDuranteDia} content={CONSTANTE_DESCRICAO_SOLICITACAO_SENTE_SONOLENCIA(strings, exame.value.indicacao.intensidade_sonolencia_fadiga)} />}
                                        {exame.value.indicacao.possui_ronco && <SttTextItem title={strings.tituloRonca} content={CONSTANTE_DESCRICAO_SOLICITACAO_POSSUI_RONCO(strings, exame.value.indicacao.possui_ronco)} />}
                                        {exame.value.indicacao.possui_dificuldade_respirar != null && <SttTextItem title={strings.tituloRelatouDificuldadeRespirar} content={exame.value.indicacao.possui_dificuldade_respirar ? strings.sim : strings.nao} />}
                                        {exame.value.indicacao.possui_movimentos_repetidos_involuntarios && <SttTextItem title={strings.movimentosRepetidosDuranteSono} content={CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS(strings, exame.value.indicacao.possui_movimentos_repetidos_involuntarios)} />}
                                        {exame.value.indicacao.possui_desconforto_pernas && <SttTextItem title={strings.desconfortoPernas} content={CONSTANTE_DESCRICAO_POSSUI_MOVIMENTOS_REPETIDOS_DESCONFORTO_PERNAS(strings, exame.value.indicacao.possui_desconforto_pernas)} />}
                                        {exame.value.indicacao.problemas_saude && <SttTextItem title={strings.problemasSaude} content={exame.value.indicacao.problemas_saude} />}

                                        {
                                            exame.value.indicacao.medicamentos?.length > 0 &&
                                            <>
                                                <div className={classes.tableWrapper}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttHidden smDown>
                                                                    <SttTableCell width="30%">
                                                                        {strings.medicamento}
                                                                    </SttTableCell>
                                                                    <SttTableCell width="70%">
                                                                        {strings.motivo}
                                                                    </SttTableCell>
                                                                </SttHidden>
                                                                <SttHidden mdUp>
                                                                    <SttTableCell width="100%" colSpan="2">{strings.medicamentos}</SttTableCell>
                                                                </SttHidden>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                exame.value.indicacao.medicamentos.map((row, index) => (
                                                                    <SttTableRow key={index}>
                                                                        <SttHidden smDown>
                                                                            <SttTableCell>{row.medicamento}</SttTableCell>
                                                                            <SttTableCell>{row.motivo}</SttTableCell>
                                                                        </SttHidden>
                                                                        <SttHidden mdUp>
                                                                            <SttTableCell width="96%">
                                                                                <SttText size="small">
                                                                                    <b>{strings.medicamento}:</b> {row.medicamento}
                                                                                </SttText>
                                                                                <SttText size="small">
                                                                                    <b>{strings.motivo}:</b> {row.motivo}
                                                                                </SttText>
                                                                            </SttTableCell>
                                                                        </SttHidden>
                                                                    </SttTableRow>
                                                                ))
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            />
                        }

                        {
                            exame.value?.exame &&
                            <SttExpansionPanel
                                title={strings.dadosExame}
                                children={
                                    <div>
                                        {exame.value.exame.observacao && <SttTextItem title={strings.observacao} content={exame.value.exame.observacao} />}
                                        {exame.value.exame.grau_desconforto_aparelho_polissonografia && <SttTextItem title={strings.grauDesconfortoAparelhoPolissonografia} content={CONSTANTE_DESCRICAO_ENVIO_EXAMES_GRAU_DESCONFORTO(strings, exame.value.exame.grau_desconforto_aparelho_polissonografia)} />}
                                        {exame.value.exame.hora_adormecer && <SttTextItem title={strings.horasDeitouDormir} content={`${exame.value.exame.hora_adormecer.hours} hora(s) e ${exame.value.exame.hora_adormecer.minutes ? exame.value.exame.hora_adormecer.minutes : '00'} minuto(s).`} />}
                                        {exame.value.exame.tempo_demora_iniciar_sono && <SttTextItem title={strings.tempoDemorouIniciarSono} content={`${exame.value.exame.tempo_demora_iniciar_sono.hours} hora(s) e ${exame.value.exame.tempo_demora_iniciar_sono.minutes ? exame.value.exame.tempo_demora_iniciar_sono.minutes : '00'} minuto(s).`} />}
                                        {exame.value.exame.quantidade_despertou_noite && <SttTextItem title={strings.vezesDespertouDuranteNoite} content={exame.value.exame.quantidade_despertou_noite} />}
                                        {exame.value.exame.tempo_dormiu_noite && <SttTextItem title={strings.tempoAchaDormiuEstaNoite} content={`${exame.value.exame.tempo_dormiu_noite.hours} hora(s) e ${exame.value.exame.tempo_dormiu_noite.minutes ? exame.value.exame.tempo_dormiu_noite.minutes : '00'} minuto(s).`} />}
                                        {exame.value.exame.grau_comparacao_sono_habitual && <SttTextItem title={strings.comparadoSonoHabitualSonoNoite} content={CONSTANTE_DESCRICAO_ENVIO_EXAMES_COMPARACAO_SONO_HABITUAL(strings, exame.value.exame.grau_comparacao_sono_habitual)} />}
                                        {exame.value.exame.especificacao_causa_desconforto && <SttTextItem title={strings.descricaoCausasDesconforto} content={exame.value.exame.especificacao_causa_desconforto} />}
                                    </div>
                                }
                            />
                        }

                        {
                            exame.value.exame.observacao_tecnica?.trim() &&
                            <SttExpansionPanel
                                title={strings.outrasInformacoes}
                                children={
                                    <div>
                                        {exame.value.exame.observacao_tecnica && <SttTextItem title={strings.observacaoTecnica} content={exame.value.exame.observacao_tecnica} />}
                                    </div>
                                }
                            />
                        }

                        {
                            temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_LAUDO) && exame.value.laudo &&
                            <SttExpansionPanel
                                title={strings.laudo}
                                children={
                                    <div dangerouslySetInnerHTML={{ __html: exame.value.laudo }}></div>
                                }
                            />
                        }
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_IMAGEM) &&
                                    <Imagens idExame={idExame.value} anexos={anexosExame} />
                                }


                                {
                                    !exame.value.exame.valido &&
                                    <SttNotification severity="info" className={classes.notification}>
                                        <SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
                                        <div className={classes.notificationContent}>
                                            <span>{`${strings.motivo}: ${exame.value.exame.motivo_invalidacao}`}</span>
                                            <span>{`${strings.responsavel}: ${exame.value.exame.responsavel_invalidacao}`}</span>
                                            <span>{`${strings.data}: ${exame.value.exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
                                        </div>
                                    </SttNotification>
                                }
                            </SttGrid>
                        </SttGrid>


                        {
                            exame.value.exame.valido &&
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            imprimirFn(idExame.value)
                                        }}
                                        nomarginleft="true"
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirProtocoloFn(exame.value.exame)
                                        }}
                                    >
                                        {strings.protocolo}
                                    </SttButton>
                                    {
                                        (permissaoBtns.value[1] ||
                                            (permissaoBtns.value[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) &&
                                        exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => priorizar.value = true}
                                        >
                                            {strings.priorizar}
                                        </SttButton>
                                    }
                                    {
                                        (((permissaoBtns.value[0] ||
                                            (permissaoBtns.value[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) &&
                                            exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                            (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                                exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                                        exame.value.exame.valido &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => invalidar.value = true}
                                        >
                                            {strings.invalidar}
                                        </SttButton>
                                    }
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            imprimirTermoFn(exame.value.exame)
                                        }}
                                    >
                                        {strings.imprimirTermo}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        }

                        {
                            (permissaoBtns.value[1] ||
                                (permissaoBtns.value[1] === undefined && temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) &&
                            priorizar && exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && exame.value.exame.valido &&
                            <Priorizar
                                priorizar={priorizar}
                                idExame={idExame.value}
                                idInstituicao={exame.value.exame.id_instituicao}
                                callback={voltar}
                            />
                        }
                        {
                            (((permissaoBtns.value[0] ||
                                (permissaoBtns.value[0] === undefined && temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) &&
                                exame.value.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                    exame.value.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                            invalidar &&
                            exame.value.exame.valido &&
                            <Invalidar
                                invalidar={invalidar}
                                idExame={idExame.value}
                                idInstituicao={exame.value.exame.id_instituicao}
                                idModalidade={exame.value.exame.id_modalidade}
                                callback={voltar} />
                        }
                    </>
            }
        </SttContainer>
    )

}

export default Visualizacao;