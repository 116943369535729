import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getHeaders } from '../../request';
import { SttTranslateHook } from '@stt-componentes/core';
import ListaAnexos from './anexos';
import { useSignalEffect } from '@preact/signals-react';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    divListaImagens: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

const Imagens = (props) => {
    useSignals();

    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { idExame, anexos } = props;
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
    const arquivosExternos = useSignal(null);

    useSignalEffect(() => {
        if (!idExame || !idExame.value) {
            return;
        }
        axios.get(`${EXAMES_API_BASE_URL}/exame/${idExame}/anexo`, { headers: getHeaders() })
            .then((response) => {
                arquivosExternos.value = response.data;
            })
            .catch(err => {
                console.log('err', err);
                // TODO: inserir notification de error
            });
    });

    return (
        <>
        
            <SttGaleriaImagens
                idExame={idExame}
                strings={strings}
                config={global.gConfig}
                tokenTelessaude={getHeaders()}
            />

            {
                arquivosExternos.value?.length > 0 &&
                <div className={classes.divListaImagens}>
                    <ListaAnexos anexos={arquivosExternos.value} />
                </div>
            }
        </>
    )

}
export default Imagens;