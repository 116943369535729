import * as yup from 'yup'

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';

import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';
import solicitanteSchema from '../../componentes/solicitacao/solicitante/validationSchema';
import indicacaoClinicaSchema from '../../componentes/solicitacao/indicacaoClinica/validationSchema';
import medicamentosSchema from '../../componentes/solicitacao/medicamentos/validationSchema';

export default (strings, campos) => {

    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const indicacaoClinica = indicacaoClinicaSchema(strings);
    const medicamentos = medicamentosSchema(strings);

    const schema =  yup.object().shape({});
    
    return schema
        .concat(paciente)
        .concat(solicitante)
        .concat(indicacaoClinica)
        .concat(medicamentos);
} 