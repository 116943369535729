import * as yup from 'yup'
import { DEMORA_INICIAR_SONO, DESPERTA_CEDO_DEMAIS, ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DEMORA_INICIAR_SONO, ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTARES_MEIO_SON, ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTA_CEDO_DEMAIS, INDICACAO_CLINICA, INTENSIDADE_SONOLENCIA_FADIGA, POSSUI_DESCONFORTO_PERNAS, POSSUI_DESPERTARES_MEIO_SONO, POSSUI_DIFICULDADE_RESPIRAR, POSSUI_MOVIMENTOS_REPETIDOS_INVOLUNTARIOS, POSSUI_RONCO, TEMPO_DEMORA_INICIAR_SONO, TEMPO_DESPERTARES_MEIO_SONO, TEMPO_DESPERTA_CEDO_DEMAIS } from './fieldNames'
import { CONSTANTE_SIM } from '../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [INDICACAO_CLINICA]: yup.object().shape({
            [DEMORA_INICIAR_SONO]: yup.string().matches(/(S|N)/).nullable().required(strings.campoObrigatorio),
            [TEMPO_DEMORA_INICIAR_SONO]: yup.number()
                .nullable()
                .when(`${DEMORA_INICIAR_SONO}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.number().required(strings.campoObrigatorio)
                }),
            [ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DEMORA_INICIAR_SONO]: yup.object()
                .nullable()
                .when(`${DEMORA_INICIAR_SONO}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).nullable().required(strings.campoObrigatorio)
                }),

            [POSSUI_DESPERTARES_MEIO_SONO]: yup.string().matches(/(S|N)/).nullable().required(strings.campoObrigatorio),
            [TEMPO_DESPERTARES_MEIO_SONO]: yup.number()
                .nullable()
                .when(`${POSSUI_DESPERTARES_MEIO_SONO}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.number().required(strings.campoObrigatorio)
                }),
            [ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTARES_MEIO_SON]: yup.object()
                .nullable()
                .when(`${POSSUI_DESPERTARES_MEIO_SONO}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).nullable().required(strings.campoObrigatorio)
                }),

            [DESPERTA_CEDO_DEMAIS]: yup.string().matches(/(S|N)/).nullable().required(strings.campoObrigatorio),
            [TEMPO_DESPERTA_CEDO_DEMAIS]: yup.number()
                .nullable()
                .when(`${DESPERTA_CEDO_DEMAIS}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.number().required(strings.campoObrigatorio)
                }),
            [INTENSIDADE_SONOLENCIA_FADIGA]: yup.string().matches(/(N|L|M|I)/).nullable().required(strings.campoObrigatorio),
            [POSSUI_RONCO]: yup.string().matches(/(S|D|N|O)/).nullable().required(strings.campoObrigatorio),
            [POSSUI_DIFICULDADE_RESPIRAR]: yup.string().matches(/(S|N)/).nullable().required(strings.campoObrigatorio),
            [ID_INDICACAO_POLISSONO_ESPECIFICACAO_TEMPO_DESPERTA_CEDO_DEMAIS]: yup.object()
                .nullable()
                .when(`${DESPERTA_CEDO_DEMAIS}`, {
                    is: (val) => val === CONSTANTE_SIM,
                    then: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).nullable().required(strings.campoObrigatorio)
                }),
            [POSSUI_MOVIMENTOS_REPETIDOS_INVOLUNTARIOS]: yup.string().matches(/(T|A|N)/).nullable().required(strings.campoObrigatorio),
            [POSSUI_DESCONFORTO_PERNAS]: yup.string().matches(/(T|A|N)/).nullable().required(strings.campoObrigatorio),
        }).required()
    });
}