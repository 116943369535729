export const CID10 = 'cid10';
export const DECS = 'decs';
export const NOME_MEDICO_SOLICITANTE = 'nomeMedicoSolicitante';
export const ID_MEDICO_SOLICITANTE = 'idMedicoSolicitante';
export const ID_PACIENTE = 'idPaciente';
export const ID_EXAME = 'idExame';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const TIMESTAMP_INICIO = 'timestampInicio';
export const DESCRICAO_ESTUDO = 'descricaoEstudo';
export const INDICE_EVENTOS_RESPIRATORIOS = 'indiceEventosRespiratorios';
export const TAXA_IER = 'taxaIer';
export const SPO2 = 'spo2'
export const OBSERVACAO = 'observacao';